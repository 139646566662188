import axios from 'axios';
import { Routes } from '../api-routes';
import { FileUri } from '../models';
import { FileName } from '../models/value-objects/file-name';
import { FileUpdate, MigrationFile, UploadFile } from '../providers';
import { apiClient } from './axios';

const PLACEHOLDER_FILE_EXTENSION = '.placeholder';

export const FileSystem = {
  /**
   *
   * @param path the name of the file
   * @param file the file content
   *
   * Uploads one file. Throws an error if unsuccessful
   */
  uploadFile: async (
    { file }: UploadFile,
    fileName: FileName,
    migrationId: string,
    template = 'Unassigned',
  ): Promise<void> => {
    const url = `${Routes.Migrations}/${migrationId}/files/upload`;
    const formData = new FormData();
    formData.append('Content-Type', file.type);
    formData.append('Content', file);
    formData.append('fileName', fileName.serialize());
    formData.append('template', template);

    return await apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  listFiles: async (migrationId: string): Promise<MigrationFile[]> => {
    const { data } = await apiClient.get<{ files: MigrationFile[] }>(`${Routes.Migrations}/${migrationId}/files/`);
    return data.files;
  },
  /**
   *
   * @param filePath the Path of the file to be deleted
   * @returns Returns void if successful, otherwise throws error.
   */
  deleteFile: async (fileId: string): Promise<void> => apiClient.delete(`${Routes.Files}/${fileId}`),

  addPlaceholderFile: async (migrationId: string, placeholder: MigrationFile): Promise<string> => {
    const url = `${Routes.Migrations}/${migrationId}/files/placeholder`;
    const { data } = await apiClient.post<string>(url, {
      placeholder: {
        ...placeholder,
        path: `/${migrationId}/placeholder${placeholder.id}${PLACEHOLDER_FILE_EXTENSION}`,
        migrationID: migrationId,
      },
    });
    return data;
  },

  getDownloadURL: async (fileHashes: string[]) =>
    apiClient.post<FileUri[]>(`${Routes.Files}/download`, {
      fileHashes,
    }),
  callFileUpdateEndpoint: async (migrationId: string, payload: FileUpdate[]) => {
    try {
      await apiClient.patch(`${Routes.Migrations}/${migrationId}/files/`, {
        fileUpdates: payload,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data.message;
      }
      throw err;
    }
  },
};
