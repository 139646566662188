import React, { useContext, useMemo, useState } from 'react';

import {
  QDrawer,
  QBox,
  QButton,
  QEmptyState,
  QHeading,
  Text,
  QButtonGroup,
  QStack,
  QFormControl,
  QInput,
  QMenuButton,
  QMenuItemType,
} from '@qualio/ui-components';
import { filter, includes, isEmpty, map, without } from 'lodash';
import { Searcher } from 'fast-fuzzy';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from '../../../types/UserPermissions';
import { MigrationDetailsForm } from './MigrationDetailsForm';
import { MigrationsTable } from './table/MigrationsTable';
import { MigrationStatus } from '../../../models';
import MigrationContext from '../../../providers/MigrationContext';

export type DrawerState = {
  visible: boolean;
  id: string;
  migrationName: string;
  changeControlStatement: string;
};

export const ListMigrations: React.FC = () => {
  const { migrations, user } = useContext(MigrationContext);
  const { t } = useTranslation(['common', 'migrations']);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [drawerState, setDrawerState] = React.useState<DrawerState>({
    visible: false,
    id: '',
    migrationName: '',
    changeControlStatement: '',
  });
  const [showStatus, setShowStatus] = useState<number[]>([0, 1, 2, 3, 4]);

  const { data = [], error, isLoading } = migrations;

  const showMigrationForm = (id?: string, name?: string, changeControlStatement?: string) => {
    setDrawerState((state) => ({
      ...state,
      visible: true,
      id: id || state.id,
      migrationName: name || state.migrationName,
      changeControlStatement: changeControlStatement || state.changeControlStatement,
    }));
  };

  const hideMigrationForm = () =>
    setDrawerState((state) => ({
      ...state,
      visible: false,
      id: '',
      migrationName: '',
    }));

  const migrationEditButtonAllowed = (status: number): boolean =>
    user.can(PERMISSIONS.CAN_CREATE_MIGRATION) &&
    status < MigrationStatus.SCHEDULED &&
    status !== MigrationStatus.ERROR;

  const migrationViewButtonAllowed = (status: number): boolean => {
    if (MigrationStatus[status] === 'DRAFT' && user.can(PERMISSIONS.CAN_UPLOAD_MIGRATION_FILES)) {
      return true;
    }
    if (
      (MigrationStatus[status] === 'PREPARING' && user.can(PERMISSIONS.CAN_PREPARE_MIGRATION)) ||
      user.can(PERMISSIONS.CAN_UPLOAD_MIGRATION_FILES)
    ) {
      return true;
    }
    if (
      (MigrationStatus[status] === 'IN_REVIEW' && user.can(PERMISSIONS.CAN_APPROVE_MIGRATION)) ||
      user.can(PERMISSIONS.CAN_PREPARE_MIGRATION)
    ) {
      return true;
    }
    if (MigrationStatus[status] === 'SCHEDULED' && user.can(PERMISSIONS.CAN_APPROVE_MIGRATION)) {
      return true;
    }
    if (user.can(PERMISSIONS.CAN_VIEW_MIGRATION)) {
      return true;
    }

    return false;
  };

  const filteredMigrations = useMemo(() => {
    if (isEmpty(showStatus)) return data;
    return filter(data, (m) => includes(showStatus, +m.status));
  }, [data, showStatus]);

  const searcher = useMemo(
    () =>
      new Searcher(filteredMigrations ? filteredMigrations : data, {
        keySelector: (plan: any) => [plan.name],
        threshold: 1,
      }),
    [data, filteredMigrations],
  );

  const searchFiltered = searchTerm ? searcher.search(searchTerm) : filteredMigrations;

  const handleSelectStatus = (status: number) => {
    if (includes(showStatus, status)) {
      setShowStatus(without(showStatus, status));
    } else {
      setShowStatus([...showStatus, status]);
    }
  };

  const statuses = {
    Draft: 0,
    Preparing: 1,
    'In Review': 2,
    Scheduled: 3,
    'In Progress': 4,
    Complete: 5,
    Error: -1,
  };

  const drawerTitle = drawerState.migrationName ? 'Edit Migration' : 'Create Migration';
  const drawerSuccessMsg = drawerState.migrationName
    ? t('migrations:lblEditMigrationSuccessMessage')
    : t('migrations:lblCreateMigrationSuccessMessage');

  if (error) {
    return <Text size="lead">{t('error')}</Text>;
  }
  if (isLoading) {
    return <Text size="lead">Loading...</Text>;
  }

  const hasData = data?.length !== 0;

  return (
    <React.Fragment>
      <QStack spacing="24px" w="full">
        <QStack direction="row" as="header" justifyContent="space-between" alignItems="flex-end">
          <QHeading size="lg">Migrations Manager</QHeading>
          {hasData && (
            <React.Fragment>
              <QButtonGroup>
                {user.can(PERMISSIONS.CAN_CREATE_MIGRATION) && (
                  <QButton onClick={() => showMigrationForm()}>Create Migration</QButton>
                )}
              </QButtonGroup>
            </React.Fragment>
          )}
        </QStack>
        {hasData ? (
          <React.Fragment>
            <QBox display="flex" gap="1em" padding="0 1em">
              <QMenuButton
                buttonLabel="Status"
                items={map(statuses, (id, label) => {
                  label = includes(showStatus, id) ? `✔ ${label}` : label;
                  return { id: `${id}`, label } as QMenuItemType;
                })}
                variant="outline"
                onItemClick={(item) => handleSelectStatus(+item.id)}
              />
              <QFormControl>
                <QInput
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search"
                />
              </QFormControl>
            </QBox>

            <MigrationsTable
              migrations={searchFiltered || []}
              showMigrationForm={showMigrationForm}
              migrationEditButtonAllowed={migrationEditButtonAllowed}
              migrationViewButtonAllowed={migrationViewButtonAllowed}
            />
          </React.Fragment>
        ) : (
          <QEmptyState
            title="You have not created any migrations yet!"
            subtitle="Click on Create Migration to get started"
            primaryButtonProps={{
              label: 'Create Migration',
              onClick: () => showMigrationForm(),
            }}
          />
        )}
      </QStack>

      <QDrawer title={drawerTitle} size="xl" isOpen={drawerState.visible} onClose={hideMigrationForm}>
        {drawerState.visible && (
          <MigrationDetailsForm onClose={hideMigrationForm} migration={drawerState} successMessage={drawerSuccessMsg} />
        )}
      </QDrawer>
    </React.Fragment>
  );
};
