import { environment } from '../environments/environment';
import { ApiTag } from '../models';
import { apiClient } from './axios';

export const TagsApi = {
  fetchTags: async (companyID: number): Promise<ApiTag[]> => {
    const { data } = await apiClient.get(`${environment.PUBLIC_API}/tags?company_id=${companyID}`);

    return data;
  },
};
