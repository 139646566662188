import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { MigrationDTO } from '../models/data-transfer-objects/migration-dto';
import { MigrationSummaryDTO } from '../models/data-transfer-objects/migration-summary-dto';
import { MigrationStatus } from '../models/enums';
import { MigrationsApi } from '../services';
import { CACHE_KEYS } from '../utils/react-query';
import { useCurrentUser, useAnalytics, useToastProvider } from '@qualio/ui-components';
import { STATUSES, STALE_USER_COMPANY_VALIDATION_ERROR_MESSAGE } from '../constants';

/* ******************************** SETUP ******************************** */
const placeholderMigration: MigrationDTO = {
  changeControlStatement: 'Loading...',
  id: 'Loading...',
  name: 'Loading...',
  progress: 0,
  indexFileDownloadURL: 'Loading...',
  startedAt: '',
  completedAt: '',
  status: 'DRAFT',
};

/* ******************************* QUERIES ******************************* */
export const useCurrentMigrationId = () => {
  const migrationId = useLocation().pathname;
  if (!migrationId) throw new Error('No migration active');
  return migrationId;
};

export const useMigrationSummaries = () => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  return useQuery([CACHE_KEYS.MIGRATIONS, companyId], () => {
    try {
      return MigrationsApi.fetchMigrations(companyId);
    } catch (e: any) {
      if (e.response.status === 403) {
        showToast({
          title: 'Invalid user token',
          description: STALE_USER_COMPANY_VALIDATION_ERROR_MESSAGE,
          duration: 5000,
          status: 'warning',
          id: `warning-toast-1`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        showToast({
          title: 'Migration Error',
          description: `Could not fetch existing migrations for company ID: ${companyId}. Please contact suppport.`,
          status: 'error',
          duration: 10000,
          id: `error-toast-1`,
        });
      }
    }
  });
};

export const useCurrentMigration = () => {
  const currentMigrationId = useCurrentMigrationId();
  // Dont query if missing migration id
  const enabled = currentMigrationId !== '/';

  const { data, ...props } = useQuery(
    [CACHE_KEYS.MIGRATIONS, currentMigrationId],
    () => MigrationsApi.fetchMigration(currentMigrationId),
    { enabled },
  );
  return {
    data: data ?? placeholderMigration,
    ...props,
  };
};

export const useFetchIndexFile = () => {
  const { data: currentMigration } = useCurrentMigration();
  return useQuery(
    [CACHE_KEYS.MIGRATIONS, currentMigration.id, 'INDEX'],
    async () => {
      const { data } = await MigrationsApi.getIndexDownloadUrl(currentMigration);

      return data;
    },
    {
      enabled: false, // This prevents the api call from happening before the "refetch" function is called
    },
  );
};

/* ****************************** MUTATIONS ****************************** */

function useDefaultMigrationsMutationOptions<T>() {
  const queryClient = useQueryClient();
  return {
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error: unknown, variables: T, context?: { previousSummaries: MigrationSummaryDTO[] | undefined }) => {
      if (context?.previousSummaries) {
        queryClient.setQueryData<MigrationSummaryDTO[]>(CACHE_KEYS.MIGRATIONS, context.previousSummaries);
      }
      throw new Error(error as any);
    },
    // Always refetch after error or success:
    onSettled: () => {
      void queryClient.invalidateQueries(CACHE_KEYS.MIGRATIONS);
    },
  };
}

function useDefaultMigrationMutationOptions() {
  const id = useCurrentMigrationId();
  const queryClient = useQueryClient();

  return {
    // Always refetch after error or success:
    onSettled: () => {
      void queryClient.invalidateQueries([CACHE_KEYS.MIGRATIONS, id]);
    },
  };
}

export const useCompleteMigration = () => {
  const id = useCurrentMigrationId();

  return useMutation(
    async () => {
      await MigrationsApi.completeMigration(id);
    },
    { ...useDefaultMigrationsMutationOptions() },
  );
};

export const useRedoMigration = () => {
  const id = useCurrentMigrationId();
  const queryClient = useQueryClient();

  return useMutation(async () => await MigrationsApi.redoMigration(id), {
    // Always refetch after error or success:
    onSettled: (data) => {
      void queryClient.invalidateQueries(CACHE_KEYS.MIGRATIONS);
      return data;
    },
  });
};

export const useSetNextStep = () => {
  const { t } = useTranslation(['migrations']);
  const confirmApproveMessage = t('migrations:lblConfirmApproveReview');
  const confirmCompletionMessage = t('migrations:lblConfirmCompletion');

  const { data: currentMigration } = useCurrentMigration();
  const newStatus = MigrationStatus[currentMigration.status] + 1;
  const analytics = useAnalytics();
  const { companyId, userId } = useCurrentUser();

  return useMutation(
    async () => {
      if (newStatus === MigrationStatus.SCHEDULED) {
        const approved = window.confirm(confirmApproveMessage);

        if (!approved) {
          throw new Error('Not Approved');
        }

        try {
          await MigrationsApi.startMigration(currentMigration).then(() => {
            analytics.track(`migration-SCHEDULED`, { groupId: companyId, userId });
          });
          return;
        } catch (error) {
          log.error(error);
          throw new Error(error as any);
        }
      }
      if (newStatus === MigrationStatus.COMPLETE) {
        const allowComplete = window.confirm(confirmCompletionMessage);
        if (!allowComplete) {
          throw new Error('Rejected Completion');
        }
        try {
          await MigrationsApi.completeMigration(currentMigration.id).then(() => {
            analytics.track(`migration-COMPLETED`, { groupId: companyId, userId });
          });
          return;
        } catch (error) {
          log.error(error);
          throw new Error(error as any);
        }
      }
      await MigrationsApi.updateStatus(currentMigration.id, newStatus).then(() => {
        const status = STATUSES[newStatus] || 'UNKNOWN';
        analytics.track(`migration-${status}`, { groupId: companyId, userId });
      });
    },
    {
      ...useDefaultMigrationMutationOptions(),
    },
  );
};

export const useSetPreviousStep = () => {
  const { data: currentMigration } = useCurrentMigration();
  const newStatus = MigrationStatus[currentMigration.status] - 1;
  const analytics = useAnalytics();
  const { companyId, userId } = useCurrentUser();

  return useMutation(
    async () => {
      await MigrationsApi.updateStatus(currentMigration.id, newStatus).then(() => {
        const status = STATUSES[newStatus] || 'UNKNOWN';
        analytics.track(`migration-${status}`, { groupId: companyId, userId });
      });
    },
    { ...useDefaultMigrationMutationOptions() },
  );
};
