import React, { useEffect, useMemo } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '300px',
};

const activeStyle = {
  borderColor: '#0C63FF',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const FileInput: React.FC<{
  onFilesAccepted: (files: FileWithPath[]) => void;
  disabled: boolean;
}> = ({ onFilesAccepted, disabled, children }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    disabled: disabled,
  });

  useEffect(() => {
    onFilesAccepted(acceptedFiles);
  }, [onFilesAccepted, acceptedFiles]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div {...getRootProps({ style: style as any })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
