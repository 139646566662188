import React from 'react';
import ReactDOM from 'react-dom';
import log from 'loglevel';
import { App } from './App';
import { QualioQFrontEndProvider, ToastProvider } from '@qualio/ui-components';
import { BrowserRouter } from 'react-router-dom';
import { environment } from './environments/environment';
import { CurrentUser } from './models';
import { GlobalProvider } from './providers/GlobalProvider';
import { mockCurrentUser } from './__mocks__/mockCurrentUser';
import { ErrorBoundary } from './components';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import 'react-contexify/dist/ReactContexify.css';
import './styles.css';

const isLocal = environment.environmentName === 'development';

const mockAnalytics = {
  track: (message: string, data: object) => console.log(`Tracked Event: ${message}`, data),
} as SegmentAnalytics.AnalyticsJS;

(window as any).renderMigrationsManager = async (
  containerId: string,
  currentUser: CurrentUser | undefined,
  analytics?: SegmentAnalytics.AnalyticsJS | undefined,
) => {
  if (isLocal) {
    currentUser = mockCurrentUser;
    analytics = mockAnalytics;
  }

  if (!currentUser) {
    log.error('You need to be logged in to access this page');
    window.location.replace(environment.loginUrl);
    return;
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.ldClientSideId,
    user: {
      key: currentUser?.companyId + '',
    },
    options: {},
  });

  ReactDOM.render(
    <ErrorBoundary>
      <LDProvider>
        <BrowserRouter basename="/migrations-manager">
          <QualioQFrontEndProvider user={currentUser} analytics={analytics}>
            <GlobalProvider currentUser={currentUser}>
              <ToastProvider>
                <App />
              </ToastProvider>
            </GlobalProvider>
          </QualioQFrontEndProvider>
        </BrowserRouter>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).unmountMigrationsManager = (containerId: string) => {
  try {
    const id = document.getElementById(containerId);

    if (!id) {
      log.error('Unable to find container');
      throw new Error('Container not found');
    }

    ReactDOM.unmountComponentAtNode(id);
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};
