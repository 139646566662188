import { environment } from '../environments/environment';
import { ApiDocumentCode, ApiTemplate, ApiTemplateDTO } from '../models';
import { apiClient } from './axios';

const unassignedTemplate = {
  name: 'Unassigned',
  displayName: 'Unassigned',
};

export const TemplatesApi = {
  fetchTemplates: async (companyID: number): Promise<ApiTemplate[]> => {
    const { data } = await apiClient.get(`${environment.PUBLIC_API}/document_templates?company_id=${companyID}`);

    const templates = data
      .filter(({ deleted }: ApiTemplateDTO) => !deleted)
      .map(({ id, document_id_prefix, name, key }: ApiTemplateDTO) => ({
        id,
        name,
        key: key,
        prefix: document_id_prefix,
        displayName: `${document_id_prefix.replace('-', ' -')} ${name}`,
      }))
      .sort((a: ApiTemplate, b: ApiTemplate) => (a.displayName > b.displayName ? 1 : -1));
    return [...templates, unassignedTemplate];
  },

  fetchDocumentCode: async (
    companyID: number,
    templateID?: number,
    templatePrefix?: string,
  ): Promise<ApiDocumentCode> => {
    if (!templateID) {
      return {
        code: 0,
      };
    }

    const { data } = await apiClient.get<string>(
      `${environment.PUBLIC_API}/v2/${companyID}/template/${templateID}/next_document_code`,
    );
    const codeNumber = parseNumberFromDocumentCode(data);
    if (codeNumber === -1) {
      console.error(`could not determine document code for template ${templateID} ${templatePrefix}`);
      throw new Error(`could not determine document code for template ${templatePrefix}`);
    }

    return {
      templateID,
      code: codeNumber,
      templatePrefix,
    };
  },
};

const parseNumberFromDocumentCode = (documentCode: string): number => {
  const matchArr = [...documentCode.matchAll(/-(\d+)/g)];

  const match = matchArr.length === 1 ? matchArr[0] : undefined;
  if (match && match[1]) {
    const code = parseInt(match[1]);
    if (!Number.isNaN(code)) {
      return code;
    }
  }

  return -1;
};
