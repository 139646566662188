import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { environment } from '../environments/environment';

export const DEFAULT_LNG = 'en';

const allowedLanguages = ['en'];
const lng = DEFAULT_LNG;
const nsNames = ['common', 'files', 'migrations'];
const MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000;

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: nsNames,
    fallbackLng: DEFAULT_LNG,
    lng: lng,
    supportedLngs: allowedLanguages,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: environment.APP_URL + environment.translationsUrl,
      allowMultiLoading: true,
    },
    react: {
      useSuspense: false,
    },
    cache: {
      enabled: true,
      expirationTime: MILLIS_IN_A_DAY,
    },
  });

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const changeLanguage = (lang: string) => {
  return i18n.changeLanguage(lang);
};

export default i18n;
