import {
  QModal,
  QModalHeader,
  QText,
  QCloseButton,
  QButton,
  QModalActions,
  QModalProps,
  QModalBody,
} from '@qualio/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationModalProps extends Pick<QModalProps, 'isOpen'> {
  title: React.ReactNode;
  children: React.ReactNode;
  onConfirm(): void | Promise<void>;
  onCancel(): void | Promise<void>;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  children,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation(['common']);
  const cancelButtonLabel = t('lblCancel');
  const confirmButtonLabel = t('lblConfirm');

  return (
    <QModal onClose={onCancel} size="md" isOpen={isOpen}>
      <QModalHeader>
        <QText mr={4}>{title}</QText>
        <QCloseButton onClick={onCancel} />
      </QModalHeader>
      <QModalBody ml={4}>{children}</QModalBody>
      <QModalActions>
        <QButton onClick={onCancel} variant="outline" data-cy="confirmation-modal-cancel">
          {cancelButtonLabel}
        </QButton>
        <QButton onClick={onConfirm} data-cy="confirmation-modal-confirm">
          {confirmButtonLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};
