/* eslint-disable no-control-regex */
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import JSZip from 'jszip';
import { lookup } from 'mime-types';
import React, { useCallback, useState } from 'react';
import { Item, Menu, Separator, Submenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useTemplates } from '../../../hooks/Templates';
import { ApiTemplate, ApiUser, MigrationStatus } from '../../../models';
import { MigrationSummaryDTO } from '../../../models/data-transfer-objects/migration-summary-dto';
import { FileUpdate, MigrationFile, useMigrationFiles } from '../../../providers';
import { apiClient } from '../../../services';
import { PERMISSIONS } from '../../../types/UserPermissions';
import { resetFileOrdinals } from '../../../utils/sortDocuments';
import { DOCUMENT_MENU_ID } from './Table';
import { FileName } from '../../../models/value-objects/file-name';
import { ConfirmationModal } from '../../../components/ConfirmationModal';

export enum DrawerStateAction {
  SelectOwner,
  SelectApprovers,
}

const getTemplateKey = (template: ApiTemplate | string) => {
  return typeof template === 'string' ? template : `${template?.key}`;
};

export const filterMigrationSummaries = (summaries: MigrationSummaryDTO[]) => {
  return summaries.filter((migration) => {
    return (
      migration.status !== MigrationStatus.COMPLETE &&
      migration.status !== MigrationStatus.IN_PROGRESS &&
      migration.status !== MigrationStatus.SCHEDULED &&
      migration.status !== MigrationStatus.ERROR
    );
  });
};

type FileMenuProps = {
  data: any[];
  migrationSummaries: MigrationSummaryDTO[];
  previewFile: (file: MigrationFile) => Promise<void>;
  reorderData: (rowId: string, placesMoved: number, dataToUse?: MigrationFile[]) => MigrationFile[];
  selected: MigrationFile[];
  showDrawer: (
    action: DrawerStateAction.SelectOwner | DrawerStateAction.SelectApprovers,
    files: MigrationFile[],
  ) => void;
};

export const FileMenu: React.FC<FileMenuProps> = ({
  data,
  migrationSummaries,
  previewFile,
  selected,
  showDrawer,
  reorderData,
}) => {
  const {
    getMaxOrdinal,
    updateFile,
    downloadFiles,
    deleteFile,
    getDocumentsInTemplate,
    getNumberOfDocumentAttachments,
    addPlaceholder,
  } = useMigrationFiles();

  const { t } = useTranslation(['common', 'migrations']);
  const { showToast } = useToastProvider();
  const {
    templates: { data: templates },
  } = useTemplates();

  const isPlaceholderInSelected = selected.some((s) => s.isPlaceholder);
  const userCannotPrepareMigration = useCurrentUser().cannot(PERMISSIONS.CAN_PREPARE_MIGRATION);
  const userCanViewMigration = useCurrentUser().can(PERMISSIONS.CAN_VIEW_MIGRATION);

  const isSetParentDocumentMenuHidden = (
    {
      values,
    }: {
      values: MigrationFile;
    },
    template: string,
  ) =>
    values.template !== template ||
    getDocumentsInTemplate(template) === 1 ||
    selected.some((document) => getNumberOfDocumentAttachments(document.id) > 0);

  const setParentDocument = useCallback(
    async (property: 'parentDocument', value: string) => {
      const parentDocument = data.find((file) => file.id === value);
      let newData = data;

      // Use forEach to reorder files correctly
      selected.forEach((original) => {
        const passParentDocument = parentDocument.ordinal < (original.ordinal || 0) ? 1 : 0;
        const movedSpaces = parentDocument.ordinal + passParentDocument - (original.ordinal || 0);

        newData = reorderData(original.id, movedSpaces, newData);
      });

      const selectedIDs = selected.map(({ id }) => id);
      const updates = newData.map((file, index) => {
        if (selectedIDs.includes(file.id)) {
          return {
            fileId: file.id,
            properties: {
              ordinal: file.ordinal || index,
              [property]: value || null,
            },
          };
        }
        return {
          fileId: file.id,
          properties: {
            ordinal: file.ordinal || index,
          },
        };
      });
      void updateFile(updates);
    },
    [data, reorderData, selected, updateFile],
  );

  const validateFileName = (filename: string | null) => {
    try {
      if (!filename) {
        return {
          success: false,
          errors: 'Filename cannot be empty.',
        };
      }
      new FileName(filename);
    } catch (e) {
      if (e instanceof Error) {
        return { success: false, errors: e.message };
      } else {
        throw e;
      }
    }
    return { success: true, errors: null };
  };

  const rename = async ({ values }: { values: MigrationFile }) => {
    const lblNewFileName = t('migrations:lblNewFileName');
    const fileName = window.prompt(lblNewFileName, values.fileName);
    const result = validateFileName(fileName);
    if (!fileName || result.success === false) {
      showToast({
        title: 'Error',
        description: 'Invalid Filename. ' + result.errors,
        status: 'error',
        duration: 100000,
      });
      return;
    }
    try {
      await updateFile([
        {
          fileId: values.id,
          properties: { fileName },
        },
      ]);
    } catch (err) {
      showToast({
        title: 'Error',
        description: JSON.stringify(err),
        status: 'error',
      });
    }
  };

  const deleteFiles = useCallback(async () => {
    setModalIsOpen(false);
    await Promise.all(selected.map((file) => deleteFile(file.id)));
  }, [deleteFile, selected]);

  const download = useCallback(async () => {
    const selectedFiles = selected.filter((row) => {
      return !row.isPlaceholder;
    });
    const hashes = new Map(
      selectedFiles.map((v) => {
        return [v.hash, v.fileName];
      }),
    );
    const uriList = await downloadFiles(Array.from(hashes.keys()));

    // Download the file on its own if only 1 file, otherwise create zip
    if (uriList && uriList.length === 1) {
      const fileName = hashes.get(uriList[0].fileHash);
      if (fileName) {
        const { data } = await apiClient.get(uriList[0].url, {
          responseType: 'arraybuffer',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([data], {
            type: lookup(fileName) || 'application/octet-stream',
          }),
        );
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      }
    } else if (uriList && uriList.length > 1) {
      const fileZipName = `export-${new Date().toISOString()}`;
      const zip = new JSZip();
      const fileZip = zip.folder(fileZipName);
      const failedFiles: string[] = [];
      for (const uri of uriList) {
        const fileName = hashes.get(uri.fileHash);
        if (fileName && fileZip) {
          try {
            const { data } = await apiClient.get(uri.url, {
              responseType: 'arraybuffer',
            });
            const blob = new Blob([data], {
              type: lookup(fileName) || 'application/octet-stream',
            });
            fileZip.file(fileName, blob);
          } catch (e) {
            failedFiles.push(fileName);
          }
        }
      }
      if (failedFiles.length > 0 && fileZip) {
        const blob = new Blob([failedFiles.join('\n')], {
          type: 'application/octet-stream',
        });
        fileZip.file('failed_downloads.txt', blob);
      }
      void zip.generateAsync({ type: 'blob' }).then(function (content: any) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(content);
        link.download = `${fileZipName}.zip`;
        document.body.appendChild(link);
        link.click();
      });
    }
  }, [downloadFiles, selected]);

  const evaluateUpdate = async (property: string, value: any) => {
    const isChangingMigration = property === 'migrationID';
    const filesSelected = selected.length > 0;
    const currentMigrationID = data.length && data[0].migrationId;
    const invalidMove = currentMigrationID === value;
    const invalidUpdate = !filesSelected || invalidMove;

    if (!isChangingMigration || (isChangingMigration && invalidUpdate)) {
      return { isChangingMigration, invalidUpdate, maxOrdinal: 0 };
    }

    const maxOrdinal = (await getMaxOrdinal(`${value}`)) || 0;

    return { isChangingMigration, invalidUpdate, maxOrdinal };
  };

  const updateFiles = useCallback(
    async (property: keyof MigrationFile, value: string | number | boolean | ApiUser | ApiUser[] | null) => {
      const { isChangingMigration, invalidUpdate, maxOrdinal } = await evaluateUpdate(property, value);
      if (isChangingMigration && invalidUpdate) {
        return;
      }
      const updates: FileUpdate[] = [];
      selected.forEach((original: any, idx: number) => {
        updates.push({
          fileId: original.id,
          properties: {
            [property]: value,
            ordinal: isChangingMigration ? maxOrdinal + 1 + idx : original.ordinal,
          },
          original,
        });
        const attachments = data.filter(({ parentDocument }) => parentDocument === original.id);
        attachments.forEach((attachment: any) => {
          updates.push({
            fileId: attachment.id,
            properties: {
              [property]: value,
              ordinal: isChangingMigration ? maxOrdinal + 1 : attachment.ordinal,
            },
            original: attachment,
          });
        });
      });

      await updateFile(updates);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, selected, updateFile],
  );

  const handleSetAsDocument = useCallback(
    async (fileID: string) => {
      const newFiles = reorderData(fileID, 0);
      const updates = newFiles.map((file, index) => {
        if (fileID === file.id) {
          return {
            fileId: file.id,
            properties: {
              ordinal: file.ordinal || index,
              parentDocument: null,
            },
          };
        }
        return {
          fileId: file.id,
          properties: {
            ordinal: file.ordinal || index,
          },
        };
      });
      await updateFile(updates);
    },
    [reorderData, updateFile],
  );

  const updateTemplate = useCallback(
    async (template: ApiTemplate) => {
      // Get the highest ordinal so we can place the newly moved document at the end
      let maxOrdinal = Math.max(
        ...data.map(function (o) {
          return o.ordinal;
        }),
      );

      // Assign new template and ordinal values to selected files and its attachments
      // Assigning highest ordinal, so newly assigned file always appear at end of template list
      selected.forEach((selectedFile) => {
        const file = data.find((file) => file.id === selectedFile.id);
        file.templateID = template?.id || null;
        file.templateKey = template?.key || null;
        file.template = template.name;
        file.ordinal = maxOrdinal++;

        const attachments = data.filter(({ parentDocument }) => parentDocument === file.id);
        attachments.forEach((attachment) => {
          attachment.templateID = template?.id || null;
          attachment.templateKey = template?.key || null;
          attachment.template = template.name;
          attachment.ordinal = maxOrdinal++;
        });
      });

      // Reset the numbering so that all files with same templates have ordinals are grouped together
      const newSort = resetFileOrdinals(data);
      const updates: FileUpdate[] = newSort.map((file) => ({
        fileId: file.id,
        properties: {
          templateID: file.templateID,
          templateKey: file.templateKey,
          template: file.template,
          ordinal: file.ordinal,
        },
        file,
      }));

      await updateFile(updates);
    },
    [data, selected, updateFile],
  );

  const insertRecord = async (direction: number, selectedIndex: number, selectedData: MigrationFile) => {
    // If direction is -1, insert at current position and push selected items down
    // If direction is 1, insert after selectedIndex and push subsequent items down
    const placeholderIndex = direction < 0 ? selectedIndex : selectedIndex + 1;
    const placeholderOrdinal = direction < 0 ? selectedData.ordinal! : selectedData.ordinal! + 1;

    const arrToSort = [...data];
    let lastOrdinal = placeholderOrdinal;
    arrToSort.forEach((file) => {
      if (placeholderOrdinal <= file.ordinal) {
        if (lastOrdinal >= file.ordinal) {
          file.ordinal++;
        }
        lastOrdinal = file.ordinal;
      }
    });

    const newPlaceholder = {
      id: v4(),
      tags: [],
      hash: '',
      template: selectedData.template,
      templateID: selectedData.templateID,
      templateKey: selectedData.templateKey,
      approvers: selectedData.approvers,
      bulkApprove: false,
      fileName: t('migrations:txtPlaceholderFile'),
      owner: selectedData.owner,
      ordinal: placeholderOrdinal,
      parentDocument: selectedData.parentDocument ?? selectedData.id,
      isPlaceholder: true,
      isDocumentContent: false,
      migrationID: selectedData.migrationID,
      doNotImport: selectedData.doNotImport,
    } as MigrationFile;

    await addPlaceholder(newPlaceholder);
    arrToSort.splice(placeholderIndex, 0, newPlaceholder);
    const updates: FileUpdate[] = arrToSort.map((file) => {
      return {
        fileId: file.id,
        properties: {
          ordinal: file.ordinal,
        },
      };
    });
    void updateFile(updates);
  };

  const getMenus = (): HTMLCollectionOf<Element> => {
    return document.getElementsByClassName('react-contexify react-contexify__will-enter--scale');
  };

  const getSubmenus = (): HTMLCollectionOf<Element> => {
    return document.getElementsByClassName('react-contexify react-contexify__submenu');
  };

  const updateSubmenus = (): void => {
    const mainMenu: any = getMenus().length ? getMenus()[0] : null;

    if (!mainMenu || !mainMenu.style) return;

    const submenus = getSubmenus();
    if (!submenus) return;

    const { innerHeight, innerWidth } = window;

    Array.from(submenus).forEach(function (sub: any) {
      const parentRect: DOMRect = sub.parentElement.getBoundingClientRect();

      const estateLeft = parentRect.x;
      const estateRight = innerWidth - (parentRect.x + parentRect.width);

      if (
        parentRect.x + parentRect.width + sub.offsetWidth > innerWidth &&
        parentRect.y + sub.offsetHeight > innerHeight
      ) {
        sub.style = {};
        if (estateLeft > estateRight) sub.style.right = '100%';
        //  shift up only
        else sub.style.left = '100%'; //  shift left and up
        sub.style.top = 'auto';
        sub.style.bottom = '0px';
      } else if (parentRect.x + parentRect.width + sub.offsetWidth > innerWidth) {
        if (estateLeft > estateRight) {
          //  shift left or keep as is
          sub.style = {};
          sub.style.botom = 'initial';
          sub.style.right = '100%';
        }
      } else if (parentRect.y + sub.offsetHeight > innerHeight) {
        //  shift up
        sub.style = {};
        sub.style.left = '100%';
        sub.style.top = 'auto';
        sub.style.bottom = '0px';
      }
    });
  };

  let lastX: any = null;
  let lastY: any = null;

  const onMenuMouseOver = () => {
    const mainMenu: any = getMenus().length ? getMenus()[0] : null;

    if (!mainMenu || !mainMenu.style) {
      lastX = null;
      lastY = null;
      return;
    }

    const { style } = mainMenu;

    if (lastX === style.left && lastY === style.top) return;

    //  Allow sufficient time for React to propagate DOM changes.
    setTimeout(() => process.nextTick(() => updateSubmenus()), 500);

    lastX = style.left;
    lastY = style.top;
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const numSelectedFiles = selected.length;
  const deleteModalTitle = t('files:lblConfirmDeleteModalTitle');
  const deleteModalMessage =
    numSelectedFiles === 1
      ? t('files:lblConfirmDeleteSingleFileModalMessage')
      : t('files:lblConfirmDeleteMultipleFileModalMessage', {
          numFiles: numSelectedFiles,
        });

  return (
    <div id="contextMenuContainer" onMouseOver={onMenuMouseOver}>
      <ConfirmationModal
        isOpen={modalIsOpen}
        title={deleteModalTitle}
        onCancel={() => setModalIsOpen(false)}
        onConfirm={deleteFiles}
      >
        <p>{deleteModalMessage}</p>
        <ul>
          {selected.map((file) => {
            return <li key={`file-${file.id}`}>{file.fileName}</li>;
          })}
        </ul>
      </ConfirmationModal>
      <Menu id={DOCUMENT_MENU_ID}>
        <Item onClick={({ props }) => insertRecord(-1, props.index, props.original)} hidden={selected.length > 1}>
          {t('migrations:lblInsertPlaceholderAbove')}
        </Item>
        <Item onClick={({ props }) => insertRecord(1, props.index, props.original)} hidden={selected.length > 1}>
          {t('migrations:lblInsertPlaceholderBelow')}
        </Item>
        {selected.length > 1 ? null : <Separator />}
        <Item
          disabled={userCannotPrepareMigration}
          onClick={({ props }) => rename(props)}
          hidden={selected.length > 1 || isPlaceholderInSelected}
        >
          {t('migrations:lblRenameFile')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('bulkApprove', true)}
          hidden={
            selected.filter((f) => f.bulkApprove === true || f.parentDocument).length === selected.length ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblMarkAsApproved')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('bulkApprove', false)}
          hidden={
            selected.some((f) => f.bulkApprove === false || f.parentDocument) ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblClearAsApproved')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('doNotImport', true)}
          hidden={
            selected.filter((f) => f.doNotImport === true || f.parentDocument).length === selected.length ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblAddDoNotMigrateFlag')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('doNotImport', false)}
          hidden={
            selected.some((f) => f.doNotImport === false || f.parentDocument) ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblRemoveDoNotMigrateFlag')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('isDocumentContent', false)}
          hidden={
            selected.some((f) => f.isDocumentContent === false || f.parentDocument) ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblClearAsDocumentContent')}
        </Item>
        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => updateFiles('isDocumentContent', true)}
          hidden={
            selected.filter((f) => f.isDocumentContent === true || f.parentDocument).length === selected.length ||
            userCannotPrepareMigration ||
            isPlaceholderInSelected
          }
        >
          {t('migrations:lblSetAsDocumentContent')}
        </Item>

        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => showDrawer(DrawerStateAction.SelectApprovers, selected)}
          hidden={({ props }) => props.values.parentDocument}
        >
          {t('migrations:lblSetApprovers')}
        </Item>

        <Item
          disabled={userCannotPrepareMigration}
          onClick={() => showDrawer(DrawerStateAction.SelectOwner, selected)}
          hidden={({ props }) => props.values.parentDocument}
        >
          {t('migrations:lblSetOwner')}
        </Item>
        {templates?.map((template) => (
          <Submenu
            key={getTemplateKey(template)}
            label={t('migrations:lblSetParentDocument')}
            hidden={({ props }) =>
              isSetParentDocumentMenuHidden(props, template.name) ||
              userCannotPrepareMigration ||
              isPlaceholderInSelected
            }
          >
            {data
              .filter(
                (file: MigrationFile) =>
                  (template.key && file.templateKey
                    ? template.key === file.templateKey
                    : template.id && file.templateID
                    ? template.id === file.templateID
                    : template.name === file.template) &&
                  !file.parentDocument &&
                  !file.isPlaceholder &&
                  !selected.find((f) => f.id === file.id),
              )
              .map((file) => (
                <Item
                  disabled={userCannotPrepareMigration}
                  key={file.id}
                  onClick={() => setParentDocument('parentDocument', file.id)}
                >
                  {file.fileName}
                </Item>
              ))}
          </Submenu>
        ))}

        <Item
          disabled={userCannotPrepareMigration}
          hidden={({ props }) => !props.values.parentDocument || isPlaceholderInSelected}
          onClick={({ props }) => handleSetAsDocument(props.values.id)}
        >
          {t('migrations:lblSetAsDocument')}
        </Item>

        <Submenu
          label={t('migrations:lblSetTemplate')}
          hidden={({ props }) => props.values.parentDocument || isPlaceholderInSelected}
        >
          {templates?.map((template) => (
            <Item
              disabled={userCannotPrepareMigration}
              key={getTemplateKey(template)}
              onClick={() => updateTemplate(template)}
            >
              {template.displayName}
            </Item>
          ))}
        </Submenu>
        {(migrationSummaries.length || 1) > 1 && (
          <Submenu
            label={t('migrations:lblChangeMigration')}
            hidden={() => (migrationSummaries.length || 1) === 1 || isPlaceholderInSelected}
          >
            {filterMigrationSummaries(migrationSummaries).map((migration) => (
              <Item
                disabled={userCannotPrepareMigration}
                key={migration.id}
                onClick={() => updateFiles('migrationID', migration.id)}
              >
                {migration.name}
              </Item>
            ))}
          </Submenu>
        )}
        {userCannotPrepareMigration ? '' : <Separator />}
        <Item disabled={userCannotPrepareMigration} onClick={() => setModalIsOpen(true)} hidden={selected.length > 1}>
          {t('lblDelete')}
        </Item>
        <Item disabled={userCannotPrepareMigration} onClick={() => setModalIsOpen(true)} hidden={selected.length < 2}>
          {t('lblDeleteSelected')}
        </Item>

        {userCannotPrepareMigration || isPlaceholderInSelected ? '' : <Separator />}
        <Item disabled={!userCanViewMigration} onClick={download}>
          {t('lblDownload')}
        </Item>
        <Item
          disabled={!userCanViewMigration}
          hidden={selected.length > 1 || isPlaceholderInSelected}
          onClick={({ props }) => previewFile(props.values)}
        >
          {t('lblPreview')}
        </Item>
      </Menu>
    </div>
  );
};
