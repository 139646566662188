import { QBox, QButton, QDivider, QRadio, QText } from '@qualio/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiUser } from '../../../models';

export const SelectOwnerForm: React.FC<{
  onClose: VoidFunction;
  owners: ApiUser[];
  onSubmit: (owner: ApiUser) => void;
  preSelectedOwner: ApiUser | null;
}> = ({ onClose, owners, onSubmit, preSelectedOwner }) => {
  const { t } = useTranslation(['common', 'migrations']);
  const [owner, setOwner] = useState<ApiUser | null>(preSelectedOwner);

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    if (owner) {
      onSubmit(owner);
    }
    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className="flex--column pt-1">
      <QText>{t('files:lblSelectOwnerModalDetails')}</QText>
      {owners.map((o) => (
        <QRadio
          isDisabled={o.invite_status !== 'accepted'}
          key={o.email}
          value={o.email}
          onChange={(_event) => setOwner(o)}
          isChecked={o.email === owner?.email}
        >
          {o.fullName || o.email}
        </QRadio>
      ))}
      <QDivider my={3} />
      <QBox display="flex" justifyContent="space-between">
        <QButton variant="outline" onClick={onClose}>
          {t('lblCancel')}
        </QButton>
        <QButton type="submit">{t('lblSave')}</QButton>
      </QBox>
    </form>
  );
};
