import { StopOutlined, Text } from '@qualio/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, useExpanded, useGroupBy, useRowSelect, useTable } from 'react-table';
import { useTemplates } from '../../../hooks/Templates';
import { ApiTemplate } from '../../../models';
import { MigrationFile, useMigrationFiles } from '../../../providers';

export const Table: React.FC<{
  columns: Column<any>[];
  data: any[];
  labels: string[];
}> = ({ columns, data, labels }) => {
  const { getDocumentsInTemplate, getAttachmentsInTemplate } = useMigrationFiles();
  const getRowId = React.useCallback((row) => row.id, []);
  const {
    templates: { data: templates },
    getDocumentCode,
  } = useTemplates();
  const { getTableProps, getTableBodyProps, rows, prepareRow, toggleAllRowsExpanded } = useTable(
    {
      autoResetExpanded: false,
      data,
      columns,
      getRowId,
      initialState: {
        groupBy: ['template'],
        hiddenColumns: ['id', 'template', 'templateID', 'parentDocument', 'hash', 'doNotImport'],
      },
    },
    useGroupBy,
    useExpanded,
    useRowSelect,
  );

  React.useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded]);

  const { t } = useTranslation(['common', 'migrations']);

  // Set labels that cannot render inline
  const lblDoNotMigrate = t('migrations:lblDoNotMigrate');

  return (
    <React.Fragment>
      <table {...getTableProps()} className="mm__table">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const isHeaderRow = !row.values.id;
            const isAttachment = !!row.values.parentDocument;
            const docsInTemplate = getDocumentsInTemplate(row.groupByVal);
            const attachmentsInTemplate = getAttachmentsInTemplate(row.groupByVal);
            const template = templates?.find((template: ApiTemplate) => template.id === row.values.templateID);

            if (isHeaderRow) {
              return (
                <tr key={row.id}>
                  <td className="mm__table__cell mm__table--header" colSpan={2}>
                    <Text size="sm">
                      {row.groupByVal} (
                      {t('migrations:lblNumDocuments', {
                        count: docsInTemplate,
                      })}
                      {attachmentsInTemplate > 0
                        ? t('migrations:lblNumAttachments', {
                            count: attachmentsInTemplate,
                          })
                        : null}
                      )
                    </Text>
                  </td>

                  <td className="mm__table__cell mm__table--header">
                    <Text size="sm">{t('migrations:lblExtractContent')}</Text>
                  </td>
                  <td className="mm__table__cell mm__table--header">
                    <Text size="sm">{t('migrations:lblBulkApprove')}</Text>
                  </td>
                  <td className="mm__table__cell mm__table--header">
                    <Text size="sm">{t(labels[0])}</Text>
                  </td>
                  <td className="mm__table__cell mm__table--header">
                    <Text size="sm">{t(labels[1])}</Text>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={row.id}>
                {!isAttachment ? (
                  <td className="mm__table__cell mm__table__cell--attachment">
                    <Text marginLeft={2} size="sm">
                      <span className="inline-flex center">
                        {row.values.doNotImport ? (
                          <StopOutlined
                            style={{
                              color: 'grey',
                              cursor: 'pointer',
                              marginRight: '4px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            title={lblDoNotMigrate}
                          />
                        ) : undefined}
                        {getDocumentCode(row.original as MigrationFile, template)}
                      </span>
                    </Text>
                  </td>
                ) : (
                  <td colSpan={1} className="mm__table__cell"></td>
                )}
                {row.cells.map((cell) => {
                  const isAttachmentName = isAttachment && cell.column.Header === 'fileName';

                  return (
                    <td
                      key={`${cell.row.id}-${cell.column.Header}-${cell.value}`}
                      className={`mm__table__cell ${isAttachmentName ? 'mm__table__cell--attachment-name' : ''}`}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};
