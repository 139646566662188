import { Text, QBox, QButton, QDivider, useCurrentUser, QTag } from '@qualio/ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMigrationFiles } from '../../../providers';
import { sortFiles } from '../../../utils/sortDocuments';
import { getStatusTagType } from '../../../utils/getStatusTagType';
import { Table } from '../shared/Table';
import { PERMISSIONS } from '../../../types/UserPermissions';
import { useCurrentMigration } from '../../../hooks/Migrations';
import SharedColumns from '../SharedColumns';
import { useFlags } from 'launchdarkly-react-client-sdk';

type ReviewMigrationProps = {
  onNext: () => void;
  onPrevious: () => void;
};

const stateToStepMap = {
  DRAFT: 1,
  PREPARING: 2,
  IN_REVIEW: 3,
  SCHEDULED: 4,
  IN_PROGRESS: 4,
  COMPLETE: 5,
  ERROR: 5,
};

export const ScheduledMigration: React.FC<ReviewMigrationProps> = (props) => {
  const { t } = useTranslation(['common', 'migrations']);
  const { state, fetchFiles } = useMigrationFiles();
  const { onPrevious, onNext } = props;
  const user = useCurrentUser();
  const { data } = useCurrentMigration();
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const flags = useFlags();

  const resultsDisplay = (val: any) => {
    return val ? val.document_code || JSON.stringify(val.error) : null;
  };

  const tableHeaderLabels: string[] = ['migrations:lblStatus', 'migrations:lblLogsType'];

  const tableData = useMemo(() => sortFiles(Object.values(state.files)), [state.files]);

  const hasLinkedAttachmentsCallback = useCallback(
    (fileId: string) => tableData.some(({ parentDocument }) => parentDocument === fileId),
    [tableData],
  );

  const columns = React.useMemo(
    () => [
      ...SharedColumns(flags.fileDocumentUpload, hasLinkedAttachmentsCallback),
      {
        Header: 'status',
        accessor: 'status',
        Cell: ({
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value: string;
            row: { values: { parentDocument: string } };
          };
        }) => !parentDocument && <QTag variantColor={getStatusTagType(value)}>{value.replaceAll('_', ' ')}</QTag>,
      },
      {
        Header: 'results',
        accessor: 'results',
        Cell: ({
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value: string;
            row: { values: { parentDocument: string } };
          };
        }) => !parentDocument && <Text size="xs">{resultsDisplay(value)}</Text>,
      },
    ],
    [flags.fileDocumentUpload, hasLinkedAttachmentsCallback],
  );

  useEffect(() => {
    const fetch = async () => {
      await fetchFiles();
    };
    void fetch();
  }, [fetchFiles]);

  const { loading, errors } = state;

  if (loading) {
    return <Text>{t('lblLoading')}</Text>;
  }
  if (errors?.length !== 0) {
    return <Text>{t('error')}</Text>;
  }

  const handlePrevious = async () => {
    setIsPreviousLoading(true);
    await onPrevious();
    setIsPreviousLoading(false);
  };

  const handleNext = async () => {
    setIsNextLoading(true);
    await onNext();
    setIsNextLoading(false);
  };

  const isPreviousDisabled =
    user.cannot(PERMISSIONS.CAN_APPROVE_MIGRATION) ||
    (data?.status && stateToStepMap[data.status] >= stateToStepMap.SCHEDULED);

  const isNextDisabled =
    user.cannot(PERMISSIONS.CAN_APPROVE_MIGRATION) ||
    (data?.status && stateToStepMap[data.status] >= stateToStepMap.SCHEDULED);

  return (
    <QBox>
      {tableData.length > 0 ? <Table columns={columns} data={tableData} labels={tableHeaderLabels} /> : t('lblNoData')}
      <QDivider my={3} />
      <QBox display="flex" justifyContent="space-between">
        <QButton
          variant="outline"
          onClick={handlePrevious}
          isLoading={isPreviousLoading}
          isDisabled={isPreviousDisabled}
        >
          Previous
        </QButton>
        <QButton onClick={handleNext} isLoading={isNextLoading} isDisabled={isNextDisabled}>
          Complete
        </QButton>
      </QBox>
    </QBox>
  );
};
