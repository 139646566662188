import { MigrationError } from '../../models';
import { initialSorting, sortFiles } from '../../utils/sortDocuments';
import { FileActions, FileActionType, FileRecord, MigrationFile } from '../types';

export type MigrationFilesState = {
  errors?: MigrationError[];
  loading: boolean;
  files: FileRecord<MigrationFile>;
};
export const migrationFilesReducer: React.Reducer<MigrationFilesState, FileActions> = (
  state: MigrationFilesState,
  action: FileActions,
) => {
  switch (action.type) {
    case FileActionType.DeleteFileSuccess: {
      const files = { ...state.files };
      delete files[action.payload];
      return {
        ...state,
        files,
      };
    }
    case FileActionType.FetchFiles: {
      return {
        ...state,
        loading: true,
      };
    }
    case FileActionType.FetchFilesError: {
      return {
        ...state,
        loading: false,
      };
    }
    case FileActionType.FetchFilesSuccess: {
      let initialFiles: MigrationFile[] = [];
      if (action.payload.some((file) => file.ordinal === undefined || file.ordinal === null)) {
        initialFiles = initialSorting(action.payload);
      } else {
        initialFiles = sortFiles(action.payload);
      }

      const files = initialFiles.reduce((acc: FileRecord<MigrationFile>, cur) => {
        if (cur.parentDocument) {
          cur.template = initialFiles.find((file) => file.id === cur.parentDocument)?.template || 'Unassigned';
        }
        return {
          ...acc,
          [cur.id]: {
            ...cur,
          },
        };
      }, {});

      return {
        ...state,
        files,
        loading: false,
      };
    }

    case FileActionType.UpdateFile: {
      const { updates } = action.payload;
      let stateToUpdate = { ...state };
      updates.forEach(({ fileId, properties }) => {
        if (properties.migrationID) {
          delete stateToUpdate.files[fileId];
          stateToUpdate = {
            ...stateToUpdate,
            files: { ...stateToUpdate.files },
          };
          return;
        }
        const files = {
          ...stateToUpdate.files,
          [fileId]: { ...stateToUpdate.files[fileId], ...properties },
        };
        stateToUpdate.files = {
          ...stateToUpdate.files,
          ...files,
        };
      });

      return {
        ...state,
        ...stateToUpdate,
      };
    }

    case FileActionType.AddPlaceholder: {
      const { fileId, placeholder } = action.payload;
      const files = {
        ...state.files,
        [fileId]: { ...placeholder },
      };
      return {
        ...state,
        files,
      };
    }
    case FileActionType.FileValidationErrors: {
      if (!action.payload) return state;

      return {
        ...state,
        errors: action.payload,
      };
    }

    default:
      return state;
  }
};
