import { QIcon } from '@qualio/ui-components';
import { t } from 'i18next';
import React from 'react';

type FileNameCellProps = {
  isFileUploadEnabled?: boolean;
  isLinkedAttachment: boolean;
  hasLinkedAttachments: boolean;
  isDocumentContent: boolean;
  filename: string;
};

export const FilenameCell: React.FC<FileNameCellProps> = ({
  isFileUploadEnabled = false,
  isLinkedAttachment,
  hasLinkedAttachments,
  isDocumentContent,
  filename,
}) => {
  const tooltip = t('migrations:txtFileDocumentUploadTooltip');

  const formatFileDocumentSupportedFileTypes = [
    '.png',
    '.bmp',
    '.tiff',
    '.jpg',
    '.pdf',
    '.doc',
    '.docx',
    '.odp',
    '.pptx',
  ];

  const isFileTypeSupportedByFileDocumentUpload = formatFileDocumentSupportedFileTypes.some((ext) =>
    filename.endsWith(ext),
  );

  if (
    isLinkedAttachment ||
    hasLinkedAttachments ||
    isDocumentContent ||
    !isFileUploadEnabled ||
    !isFileTypeSupportedByFileDocumentUpload
  ) {
    return <>{filename}</>;
  }

  return (
    <>
      {filename}{' '}
      <span title={tooltip}>
        <QIcon iconName="FileText" />
      </span>
    </>
  );
};
