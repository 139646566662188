import React from 'react';
import { FilenameCell } from './shared/FilenameCell';

const SharedColumns = (fileDocumentUploadEnabled: boolean, hasLinkedAttachments: (fileId: string) => boolean) => [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'hash',
    accessor: 'hash',
  },
  {
    Header: 'template',
    accessor: 'template',
  },
  {
    Header: 'templateID',
    accessor: 'templateID',
  },
  {
    Header: 'parentDocument',
    accessor: 'parentDocument',
  },
  {
    Header: 'fileName',
    accessor: 'fileName',
    Cell: ({
      cell: { row, value },
    }: {
      cell: {
        row: any;
        value: string;
      };
    }) => {
      const {
        values: { id, isDocumentContent, parentDocument },
      } = row;
      return (
        <FilenameCell
          isFileUploadEnabled={fileDocumentUploadEnabled}
          isLinkedAttachment={!!parentDocument}
          hasLinkedAttachments={hasLinkedAttachments(id)}
          isDocumentContent={isDocumentContent}
          filename={value}
        />
      );
    },
  },
  {
    Header: 'doNotImport',
    accessor: 'doNotImport',
  },
  {
    Header: 'content',
    accessor: 'isDocumentContent',
    Cell: ({
      cell: { row, value },
    }: {
      cell: {
        row: { id: string; values: { parentDocument: string } };
        value: boolean;
      };
    }) => {
      if (row.values.parentDocument) {
        return null;
      }
      return <input disabled type="checkbox" checked={value} />;
    },
  },
  {
    Header: 'bulkApprove',
    accessor: 'bulkApprove',
    Cell: ({
      cell: { row, value },
    }: {
      cell: {
        row: { id: string; values: { parentDocument: string } };
        value: boolean;
      };
    }) => {
      if (row.values.parentDocument) {
        return null;
      }
      return <input disabled type="checkbox" checked={value} />;
    },
  },
];

export default SharedColumns;
