export enum HttpStatusCode {
  OK = 200,
  UNPROCESSABLE_ENTITY = 422,
}

export class BaseError extends Error {
  public readonly name: string;
  public readonly httpCode: HttpStatusCode;
  public readonly isOperational: boolean;

  constructor(name: string, httpCode: HttpStatusCode, isOperational: boolean, description: string) {
    super(description);
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = name;
    this.httpCode = httpCode;
    this.isOperational = isOperational;
    this.message = description;
  }
}
export class HTTP422Error extends BaseError {
  constructor(errors: { key: string; error: string } | unknown[]) {
    super('Unprocessable Entity', HttpStatusCode.UNPROCESSABLE_ENTITY, true, JSON.stringify(errors));
  }
}
