import { Title } from '@qualio/ui-components';
import * as React from 'react';

type State = {
  hasError: boolean;
};

type Props = unknown;

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Title>Something has gone wrong!</Title>;
    }

    return this.props.children;
  }
}
