export const getUploadStatusTagType = (status: string) => {
  switch (status) {
    case 'Error':
      return 'red';
    case 'Success':
      return 'green';
    default:
      return 'blue';
  }
};
