import React from 'react';
import { useMigrationSummaries } from 'src/hooks/Migrations';
import { useCurrentUser } from '@qualio/ui-components';
import MigrationContext from './MigrationContext';

type MigrationProviderProps = {
  children: JSX.Element;
};

const MigrationProvider: React.FC<MigrationProviderProps> = ({ children }) => {
  const migrations = useMigrationSummaries();
  const user = useCurrentUser();

  const value = {
    migrations,
    user,
  };

  return <MigrationContext.Provider value={value}>{children}</MigrationContext.Provider>;
};

export default MigrationProvider;
