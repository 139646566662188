import { useCurrentUser } from '@qualio/ui-components';
import log from 'loglevel';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentMigration, useCurrentMigrationId } from '../hooks/Migrations';
import { FileUri, MigrationError } from '../models';
import { FileSystem } from '../services';
import { PERMISSIONS } from '../types/UserPermissions';
import * as actions from './actions';
import { migrationFilesReducer, MigrationFilesState } from './reducers';
import { FileActionType, FileUpdates, MigrationFile } from './types';

const initialState: MigrationFilesState = {
  errors: undefined,
  loading: false,
  files: {},
};

export type FileUpdate = {
  fileId: string;
  original?: MigrationFile;
  properties: FileUpdates;
};

type ContextType = {
  state: MigrationFilesState;
  addPlaceholder: (placeholder: MigrationFile) => void;
  deleteFile: (fileId: string) => Promise<void>;
  downloadFile: (fileHash: string) => Promise<string | void>;
  downloadFiles: (fileHashes: string[]) => Promise<FileUri[] | void>;
  fetchFiles: () => Promise<void>;
  getAttachmentsInTemplate: (id: string) => number;
  getDocumentsInTemplate: (template: string) => number;
  getFileValidationErrors: (id: string) => MigrationError[];
  getNumberOfDocumentAttachments: (id: string) => number;
  selected: MigrationFile[];
  setSelected: (newSelected: MigrationFile[]) => void;
  updateFile: (updates: FileUpdate[]) => Promise<unknown | void>;
  getMaxOrdinal: (migrationId: string) => Promise<number>;
};

const MigrationFilesContext = React.createContext<ContextType>({
  state: initialState,
  addPlaceholder: () => undefined,
  deleteFile: async () => undefined,
  downloadFile: async () => '',
  downloadFiles: async () => [],
  fetchFiles: async () => undefined,
  getAttachmentsInTemplate: () => 0,
  getDocumentsInTemplate: () => 0,
  getFileValidationErrors: () => [],
  getNumberOfDocumentAttachments: () => 0,
  updateFile: async () => undefined,
  selected: [],
  setSelected: () => undefined,
  getMaxOrdinal: async () => 0,
});

const MigrationFilesProvider: React.FC = ({ children }) => {
  const currentMigrationId = useCurrentMigrationId();
  const {
    data: { logs },
  } = useCurrentMigration();
  const [state, dispatch] = useReducer(migrationFilesReducer, initialState);
  const user = useCurrentUser();
  const [selected, _setSelected] = useState<MigrationFile[]>([]);
  const { t } = useTranslation(['common', 'migrations']);

  const setSelected = useCallback(
    (newSelected: MigrationFile[]) => {
      // Deep comparison of the selected items to only update when there is a change
      if (JSON.stringify(newSelected) !== JSON.stringify(selected)) {
        _setSelected(newSelected);
      }
    },
    [_setSelected, selected],
  );

  useEffect(() => {
    dispatch(actions.fileValidationErrors(logs));
  }, [logs]);

  const fetchFiles = useCallback(async () => {
    try {
      dispatch(actions.fetchFiles());
      const files = await FileSystem.listFiles(currentMigrationId || '');
      dispatch(actions.fetchFilesSuccess(files));
    } catch (error) {
      dispatch(actions.fetchFilesError(error as Error));
    }
  }, [currentMigrationId]);

  const addPlaceholder = async (placeholder: MigrationFile) => {
    const migrationIdWithoutSlash = currentMigrationId ? currentMigrationId.substring(1) : '';
    await FileSystem.addPlaceholderFile(migrationIdWithoutSlash, placeholder);
    dispatch({
      type: FileActionType.AddPlaceholder,
      payload: {
        fileId: placeholder.id,
        placeholder,
      },
    });
  };

  const updateFile = async (updates: FileUpdate[]) => {
    try {
      if (user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION)) {
        const lblCannotUpdateFile = t('migrations:lblCannotUpdateFile');
        throw new Error(lblCannotUpdateFile);
      }
      dispatch({
        type: FileActionType.UpdateFile,
        payload: {
          updates,
        },
      });
      if (updates.length > 0) {
        await FileSystem.callFileUpdateEndpoint(currentMigrationId, updates);
      }
    } catch (error) {
      log.error(error);
      throw error;
    }
  };

  const downloadFile = async (fileHash: string) => {
    const files = await downloadFiles([fileHash]);
    if (files && files.length > 0) {
      return files[0].url;
    }
  };

  const downloadFiles = async (fileHashes: string[]) => {
    try {
      if (user.cannot(PERMISSIONS.CAN_VIEW_MIGRATION)) {
        throw new Error('migrations:lblCannotViewFile');
      }
      const { data } = await FileSystem.getDownloadURL(fileHashes);
      return data;
    } catch (error) {
      log.error(error);
      return;
    }
  };

  const deleteFile = async (fileId: string) => {
    try {
      if (user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION)) {
        throw new Error('User not permitted to delete files in a migration');
      }

      await FileSystem.deleteFile(fileId);
      dispatch(actions.deleteFileSuccess(fileId));
    } catch (error) {
      log.error(error);
    }
  };

  const getDocumentsInTemplate = (template: string) => {
    return Object.values(state.files).filter((file) => file.template === template && !file.parentDocument).length;
  };

  const getAttachmentsInTemplate = (template: string): number =>
    Object.values(state.files).filter((file) => file.template === template && file.parentDocument).length;

  const getNumberOfDocumentAttachments = (id: string): number =>
    Object.values(state.files).filter((file: MigrationFile) => file.parentDocument === id).length;

  const getFileValidationErrors = (id: string) => {
    return state.errors ? state.errors.filter((err) => err.fileId === id) : [];
  };

  const getMaxOrdinal = async (migrationId: string): Promise<number> => {
    const files: MigrationFile[] = await FileSystem.listFiles(migrationId);
    const ordinals: number[] = (files || []).map((f) => f.ordinal || 0);
    const maxOrdinal: number = Math.max(...ordinals);
    return isFinite(maxOrdinal) ? maxOrdinal : 0;
  };

  return (
    <MigrationFilesContext.Provider
      value={{
        state,
        addPlaceholder,
        deleteFile,
        downloadFile,
        downloadFiles,
        getAttachmentsInTemplate,
        getDocumentsInTemplate,
        getFileValidationErrors,
        getNumberOfDocumentAttachments,
        fetchFiles,
        updateFile,
        setSelected,
        selected,
        getMaxOrdinal,
      }}
    >
      {children}
    </MigrationFilesContext.Provider>
  );
};

const useMigrationFiles = () => {
  const context = React.useContext(MigrationFilesContext);
  if (context === undefined) {
    throw new Error('useMigrationFiles must be used within a MigrationFilesContext');
  }

  return context;
};

export { useMigrationFiles, MigrationFilesProvider, MigrationFilesContext };
