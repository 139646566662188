import { Text, QBox, QButton, QDivider } from '@qualio/ui-components';
import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiUser } from '../../../models';
import { useMigrationFiles } from '../../../providers';
import { sortFiles } from '../../../utils/sortDocuments';
import { Table } from '../shared/Table';
import { PERMISSIONS } from '../../../types/UserPermissions';
import SharedColumns from '../SharedColumns';
import MigrationContext from '../../../providers/MigrationContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

type ReviewMigrationProps = {
  onNext: () => void;
  onPrevious: () => void;
};
export const ReviewMigration: React.FC<ReviewMigrationProps> = (props) => {
  const { onNext, onPrevious } = props;
  const { t } = useTranslation(['common', 'migrations']);
  const { state, fetchFiles } = useMigrationFiles();
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const { user } = useContext(MigrationContext);
  const flags = useFlags();

  const ReviewTableHeaders: string[] = ['migrations:lblApprovers', 'migrations:lblOwners'];

  const data = useMemo(() => sortFiles(Object.values(state.files)), [state.files]);

  const hasLinkedAttachmentsCallback = useCallback(
    (fileId: string) => data.some(({ parentDocument }) => parentDocument === fileId),
    [data],
  );

  const columns = React.useMemo(
    () => [
      ...SharedColumns(flags.fileDocumentUpload, hasLinkedAttachmentsCallback),
      {
        Header: 'approvers',
        accessor: 'approvers',
        Cell: ({
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value: ApiUser[];
            row: { values: { parentDocument: string } };
          };
        }) =>
          (!parentDocument &&
            value?.map((approver) => (
              <Text size="xs" key={approver.email}>
                {approver.fullName}
              </Text>
            ))) ||
          null,
      },
      {
        Header: 'owner',
        accessor: 'owner',
        Cell: ({
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value?: ApiUser;
            row: { values: { parentDocument: string } };
          };
        }) =>
          !parentDocument && value ? (
            <Text size="xs" key={value.email}>
              {value.fullName}
            </Text>
          ) : null,
      },
    ],
    [flags.fileDocumentUpload, hasLinkedAttachmentsCallback],
  );

  useEffect(() => {
    const fetch = async () => {
      await fetchFiles();
    };
    void fetch();
  }, [fetchFiles]);

  const { loading, errors } = state;

  const handlePrevious = async () => {
    setIsPreviousLoading(true);
    await onPrevious();
    setIsPreviousLoading(false);
  };

  const handleNext = async () => {
    try {
      setIsNextLoading(true);
      await onNext();
    } catch (e) {
      console.log(e);
    } finally {
      setIsNextLoading(false);
    }
  };

  if (loading) {
    return <Text>{t('lblLoading')}</Text>;
  }
  if (errors?.length !== 0) {
    return <Text>{t('error')}</Text>;
  }

  const cannotApprove = user.cannot(PERMISSIONS.CAN_APPROVE_MIGRATION);
  const cannotPrepare = user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION);

  return (
    <React.Fragment>
      {data.length > 0 ? <Table columns={columns} data={data} labels={ReviewTableHeaders} /> : t('lblNoData')}
      <QDivider my={3} />
      <QBox display="flex" justifyContent="space-between">
        <QButton variant="outline" onClick={handlePrevious} isLoading={isPreviousLoading} isDisabled={cannotPrepare}>
          Previous
        </QButton>
        <QButton onClick={handleNext} isLoading={isNextLoading} isDisabled={cannotApprove}>
          Approve
        </QButton>
      </QBox>
    </React.Fragment>
  );
};
