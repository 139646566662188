import { MigrationFile } from '.';
import { FileUpdate } from '..';
import { ApiTag, ApiUser, MigrationError } from '../../models';

export enum FileActionType {
  DeleteFileSuccess,
  FetchFiles,
  FetchFilesError,
  FetchFilesSuccess,
  UpdateFile,
  AddPlaceholder,
  FileValidationErrors,
}

export interface AddPlaceholder {
  type: FileActionType.AddPlaceholder;
  payload: {
    fileId: string;
    placeholder: MigrationFile;
  };
}

export interface DeleteFileSuccess {
  type: FileActionType.DeleteFileSuccess;
  payload: string;
}
export interface FetchFiles {
  type: FileActionType.FetchFiles;
}
export interface FetchFilesError {
  type: FileActionType.FetchFilesError;
}
export interface FetchFilesSuccess {
  type: FileActionType.FetchFilesSuccess;
  payload: MigrationFile[];
}
export interface FileValidationErrors {
  type: FileActionType.FileValidationErrors;
  payload?: MigrationError[];
}

export type FileUpdates = {
  [key in keyof MigrationFile as string]: number | string | boolean | ApiUser | ApiUser[] | ApiTag[] | null | undefined;
};
export interface UpdateFile {
  type: FileActionType.UpdateFile;
  payload: {
    updates: FileUpdate[];
  };
}

export type FileActions =
  | DeleteFileSuccess
  | FetchFiles
  | FetchFilesError
  | FetchFilesSuccess
  | FileValidationErrors
  | UpdateFile
  | AddPlaceholder;
