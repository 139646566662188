import { useCurrentUser } from '@qualio/ui-components';
import { useCallback } from 'react';
import { useQueries, useQuery } from 'react-query';
import { ApiDocumentCode, ApiTemplate } from '../models';
import { TemplatesApi } from '../services';
import { CACHE_KEYS } from '../utils/react-query';

export const useTemplates = () => {
  const user = useCurrentUser();

  const templates = useQuery<ApiTemplate[], Error>([CACHE_KEYS.TEMPLATES, user.companyId], () =>
    TemplatesApi.fetchTemplates(user.companyId),
  );
  const documentCodes = useQueries(
    templates?.data?.map(({ id, prefix }) => ({
      queryKey: [CACHE_KEYS.TEMPLATES, user.companyId, CACHE_KEYS.DOCUMENT_CODE, id],
      queryFn: () => TemplatesApi.fetchDocumentCode(user.companyId, id, prefix),
    })) ?? [],
  );

  const getCode = useCallback(
    (templateID: number) => {
      return documentCodes.find(({ data }) => (data as ApiDocumentCode)?.templateID === templateID);
    },
    [documentCodes],
  );

  const getDocumentCode = useCallback(
    (file, template?: ApiTemplate) => {
      if (file.status === 'COMPLETE' || file.status === 'ERROR' || file.status === 'IN_PROGRESS') {
        return undefined;
      }
      if (template?.prefix && template?.id) {
        const response = getCode(template.id);
        const codeNumber = (response?.data as ApiDocumentCode)?.code || 0;

        return `${template?.prefix}${codeNumber + file.templateOrdinal}`;
      } else if (file.templateID) {
        return undefined;
      }
      return file.templateOrdinal + 1;
    },
    [getCode],
  );

  return {
    templates,
    documentCodes,
    getCode,
    getDocumentCode,
  };
};
