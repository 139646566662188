import { UploadActionType, UploadActions, FileRecord, UploadFile, UploadFileStatus } from '../types';

export type UploadFilesState = {
  filesToUpload: FileRecord<UploadFile>;
};

export const uploadFilesReducer: React.Reducer<UploadFilesState, UploadActions> = (
  state: UploadFilesState,
  action: UploadActions,
) => {
  switch (action.type) {
    case UploadActionType.CancelUpload: {
      const filesToUpload = { ...state.filesToUpload };

      delete filesToUpload[action.payload.path];

      return {
        ...state,
        filesToUpload,
      };
    }
    case UploadActionType.RetryUpload: {
      const filesToUpload = {
        ...state.filesToUpload,
        [action.payload.path]: {
          ...action.payload,
          status: UploadFileStatus.Pending,
          error: undefined,
        },
      };

      return {
        ...state,
        filesToUpload,
      };
    }
    case UploadActionType.StartUpload: {
      const filesToUpload = {
        ...state.filesToUpload,
        [action.payload.path]: {
          ...action.payload,
          status: UploadFileStatus.InProgress,
        },
      };
      return {
        ...state,
        filesToUpload,
      };
    }
    case UploadActionType.UploadError: {
      const filesToUpload = {
        ...state.filesToUpload,
        [action.payload.file.path]: {
          ...action.payload.file,
          status: UploadFileStatus.Error,
          error: action.payload.error,
        },
      };

      return {
        ...state,
        filesToUpload,
      };
    }
    case UploadActionType.UploadFiles: {
      const filesToUpload = action.payload.reduce((acc: FileRecord<UploadFile>, cur) => {
        const file: UploadFile = {
          file: cur,
          path: cur.path || cur.name,
          status: UploadFileStatus.Pending,
        };
        return {
          ...acc,
          [file.path]: file,
        };
      }, state.filesToUpload);
      return {
        ...state,
        filesToUpload,
      };
    }
    case UploadActionType.UploadSuccess: {
      const filesToUpload = {
        ...state.filesToUpload,
        [action.payload.path]: {
          ...action.payload,
          status: UploadFileStatus.Success,
          error: undefined,
        },
      };

      return {
        ...state,
        filesToUpload,
      };
    }
    default:
      return state;
  }
};
