import {
  QButton,
  QHeading,
  Text,
  useCurrentUser,
  useAnalytics,
  QStepper,
  QSteps,
  QStepperProgress,
  QStep,
  QBox,
  QDivider,
  Alert,
} from '@qualio/ui-components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentMigrationId, useCurrentMigration, useRedoMigration } from '../../../hooks/Migrations';
import { UploadFilesProvider } from '../../../providers';
import { PrepareMigration } from '../PrepareMigrations/PrepareMigration';
import { ReviewMigration } from '../Review/ReviewMigration';
import { ScheduledMigration } from '../Scheduled/ScheduledMigration';
import { UploadFiles } from '../Upload/Upload';
import { STATUSES } from '../../../constants';
import { MigrationsApi } from '../../../services';
import { MigrationStatus } from '../../../models';
import { useQueryClient } from 'react-query';
import { CACHE_KEYS } from '../../../utils/react-query';

const stateToStepMap = {
  DRAFT: 1,
  PREPARING: 2,
  IN_REVIEW: 3,
  SCHEDULED: 4,
  IN_PROGRESS: 4,
  COMPLETE: 5,
  ERROR: 5,
};

export const MigrationContainer = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'migrations']);
  const { data, error, isLoading } = useCurrentMigration();
  const id = useCurrentMigrationId();
  const user = useCurrentUser();
  const { mutateAsync: redoMigration, isLoading: isRedoingMigration } = useRedoMigration();
  const [step, setStep] = useState(0);
  const analytics = useAnalytics();

  const actualRedo = async () => {
    await redoMigration(undefined, {
      onSuccess: (data) => navigate(`/${data.id}`),
    });
  };

  useEffect(() => {
    if (isLoading) return;
    const s = (stateToStepMap[data?.status] || 1) - 1;
    setStep(s);
  }, [data, isLoading]);

  if (isLoading) {
    return <Text size="lead">{t('lblLoading')}</Text>;
  }

  if (error) {
    return <Text size="lead">{t('error')}</Text>;
  }

  const stateToAlertType = (s: string) => {
    // error, success, default, warning
    switch (s) {
      case 'COMPLETE':
        return 'success';
      case 'ERROR':
        return 'warning';
      default:
        return 'default';
    }
  };

  const stateToStepLabel = (s: string) => {
    switch (s) {
      case 'SCHEDULED':
        return t('migrations:lblStatusScheduled');
      case 'IN_PROGRESS':
        return t('migrations:lblStatusInProgress');
    }
    return t('migrations:lblSchlblStatusSchedulededuled');
  };

  // Set the labels for the steps
  const lblBackToMigrations = t('migrations:lblBackToMigrations');
  const lblComplete = t('migrations:lblComplete');

  const handleCancel = () => navigate(-1);

  const handleNext = async () => {
    if (step === 4) {
      console.log('finished stepper');
      return;
    }
    const newStatus = MigrationStatus[data.status] + 1;
    const confirmText = t('migrations:lblConfirmApproveReview');
    const confirmCompletionMessage = t('migrations:lblConfirmCompletion');
    if (newStatus === MigrationStatus.SCHEDULED) {
      const approved = window.confirm(confirmText);
      if (!approved) {
        throw new Error('Not Approved');
      }

      try {
        await MigrationsApi.startMigration(data).then(() => {
          analytics.track(`migration-SCHEDULED`, { groupId: user.companyId, userId: user.userId });
        });
      } catch (error) {
        throw new Error(error as any);
      }
    } else if (newStatus === MigrationStatus.COMPLETE) {
      const allowComplete = window.confirm(confirmCompletionMessage);
      if (!allowComplete) {
        throw new Error('Rejected Completion');
      }
      try {
        await MigrationsApi.completeMigration(data.id).then(() => {
          analytics.track(`migration-COMPLETED`, { groupId: user.companyId, userId: user.userId });
        });
      } catch (error) {
        throw new Error(error as any);
      }
    } else {
      await MigrationsApi.updateStatus(data.id, newStatus)
        .then(() => {
          const status = STATUSES[newStatus] || 'UNKNOWN';
          analytics.track(`migration-${status}`, { groupId: user.companyId, userId: user.userId });
          setStep((s) => s + 1);
        })
        .catch((error) => console.log({ error }));
    }
    queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.MIGRATIONS, id] });
  };

  const handlePrevious = async () => {
    if (step === 0) {
      console.log('already at beginning');
      return;
    }
    const newStatus = MigrationStatus[data.status] - 1;
    await MigrationsApi.updateStatus(data.id, newStatus).then(() => {
      const status = STATUSES[newStatus] || 'UNKNOWN';
      analytics.track(`migration-${status}`, { groupId: user.companyId, userId: user.userId });
      setStep((s) => s - 1);
    });
    queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.MIGRATIONS, id] });
  };

  const handleBackToMigrations = () => {
    queryClient.invalidateQueries();
    navigate('/');
  };

  return (
    <React.Fragment>
      <QBox mb={3} display="flex" height="100%">
        <QButton leftIcon="ArrowLeft" variant="link" onClick={handleBackToMigrations}>
          {lblBackToMigrations}
        </QButton>
      </QBox>
      <QHeading mb={3} ml={2}>
        {data?.name}
      </QHeading>
      <QDivider mb={5} />
      <QBox display="flex" className="StepperContainer" height="100%">
        <QStepper activeStep={step} onStepItemClicked={() => console.log('use prev/next buttons')}>
          <QSteps>
            <QStepperProgress />
            <QStep title={t('migrations:lblUploadFiles')}>
              <QHeading mb={3}>{t('migrations:lblUploadFiles')}</QHeading>
              <QDivider mb={3} />
              <UploadFilesProvider>
                <UploadFiles onNext={handleNext} onCancel={handleCancel} />
              </UploadFilesProvider>
            </QStep>
            <QStep title={t('migrations:lblPrepareMigration')}>
              <QHeading mb={3}>{t('migrations:lblPrepareMigration')}</QHeading>
              <PrepareMigration onNext={handleNext} onPrevious={handlePrevious} />
            </QStep>
            <QStep title={t('migrations:lblReviewMigration')}>
              <QHeading mb={3}>{t('migrations:lblReviewMigration')}</QHeading>
              <ReviewMigration onNext={handleNext} onPrevious={handlePrevious} />
            </QStep>
            <QStep title={t('migrations:lblStatusInProgress')}>
              <QHeading mb={3}>{t('migrations:lblStatusInProgress')}</QHeading>
              <QBox display="flex" flexDirection="column">
                <Alert title={stateToStepLabel(data?.status)}>
                  <QBox m={3}>
                    {data?.startedAt && (
                      <React.Fragment>
                        {t('migrations:lblStartedDate')} {new Date(data.startedAt || '').toLocaleDateString()} -{' '}
                        {new Date(data.startedAt || '').toLocaleTimeString()}{' '}
                      </React.Fragment>
                    )}
                    {!data?.startedAt && <React.Fragment>{t('migrations:lblNotStarted')}</React.Fragment>}
                  </QBox>
                </Alert>
                <ScheduledMigration onPrevious={handlePrevious} onNext={handleNext} />
              </QBox>
            </QStep>
            <QStep title={lblComplete}>
              <QBox display="flex" flexDirection="column">
                <QBox mb={3}>
                  <Alert type={stateToAlertType(data?.status)} title={lblComplete}>
                    {data?.completedAt && (
                      <React.Fragment>
                        {t('migrations:lblCompletionDate')} {new Date(data.completedAt || '').toLocaleDateString()} -{' '}
                        {new Date(data.completedAt || '').toLocaleTimeString()}{' '}
                      </React.Fragment>
                    )}
                    {data?.status === 'ERROR' && (
                      <React.Fragment>
                        <br />
                        {t('migrations:lblCompleteWithErrors')}
                        <QButton onClick={actualRedo} isLoading={isRedoingMigration} isDisabled={isRedoingMigration}>
                          {t('migrations:lblCorrectMigrationFiles')}
                        </QButton>
                      </React.Fragment>
                    )}
                  </Alert>
                </QBox>
                <ScheduledMigration onNext={handleNext} onPrevious={handlePrevious} />
              </QBox>
            </QStep>
          </QSteps>
        </QStepper>
      </QBox>
    </React.Fragment>
  );
};
