import { QBox, QProgress } from '@qualio/ui-components';
import React from 'react';
import i18next from 'i18next';
import { QAlert } from '@qualio/ui-components';
import { useTranslation } from 'react-i18next';
import { UploadFile, UploadFileStatus } from '../../../providers/types';

const getAlertTitle = (state: string, t: (key: string) => string) => {
  let alertTitle = '';

  switch (state) {
    case 'error':
      alertTitle = t('migrations:lblUploadFailed');
      break;
    case 'success':
      alertTitle = t('migrations:lblUploadSuccess');
      break;
    case 'warning':
      alertTitle = t('migrations:lblUploadError');
      break;
    case 'info':
      alertTitle = t('migrations:lblUploadInProgress');
      break;
  }

  return alertTitle;
};

const getAlertDescription = (errors: UploadFile[], success: UploadFile[], filesToUpload: UploadFile[]) => {
  if (errors.length > 0) {
    return i18next.t('migrations:lblUploadSuccessOutput', {
      successLength: success.length,
      filesToUploadLength: filesToUpload.length,
    });
  }

  return i18next.t('migrations:lblUploadErrorOutput', {
    successLength: success.length,
    filesToUploadLength: filesToUpload.length,
    errorsLength: errors.length,
  });
};

export const ProgressDialog: React.FC<{
  filesToUpload: UploadFile[];
}> = ({ filesToUpload }) => {
  const { t } = useTranslation(['common', 'migrations']);
  const inProgress = filesToUpload.filter(
    (file) => file.status === UploadFileStatus.Pending || file.status === UploadFileStatus.InProgress,
  );
  const uploaded = filesToUpload.filter((file) => file.status === UploadFileStatus.Success);
  const errors = filesToUpload.filter((file) => file.status === UploadFileStatus.Error);
  const success = filesToUpload.filter((file) => file.status === UploadFileStatus.Success);

  const successState = success.length === 0 ? 'error' : 'success';
  const errorState = errors.length > 0 ? 'warning' : successState;
  const finalState = inProgress.length > 0 ? 'info' : errorState;

  const alertTitle = getAlertTitle(finalState, t);
  const alertDescription = getAlertDescription(errors, success, filesToUpload);

  const percentage = (uploaded.length * 100) / filesToUpload.length;

  return (
    <QBox>
      <QProgress value={percentage} size="lg" mb={3} isIndeterminate={inProgress.length > 0 && finalState === 'info'} />
      {finalState === 'info' ? (
        <QAlert size="md" status={finalState} description="" title={alertTitle}></QAlert>
      ) : (
        <QAlert size="md" status={finalState} description={alertDescription} title={alertTitle}></QAlert>
      )}
    </QBox>
  );
};
