import log from 'loglevel';
import React, { useCallback, useEffect, useReducer } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useCurrentMigrationId } from '../hooks/Migrations';
import { FileName } from '../models/value-objects/file-name';
import { FileSystem } from '../services/file.service';
import * as actions from './actions';
import { uploadFilesReducer, UploadFilesState } from './reducers';
import { ErrorType, UploadActionType, UploadFile, UploadFileStatus } from './types';

const initialState: UploadFilesState = {
  filesToUpload: {},
};

type ContextType = {
  cancelUpload: (files: UploadFile) => void;
  filesToUpload: UploadFile[];
  retryUpload: (files: UploadFile) => void;
  uploadFiles: (files: FileWithPath[]) => void;
};

const UploadFilesContext = React.createContext<ContextType>({
  cancelUpload: () => undefined,
  retryUpload: () => undefined,
  uploadFiles: () => undefined,
  filesToUpload: [],
});

const UploadFilesProvider: React.FC = ({ children }) => {
  const currentMigrationId = useCurrentMigrationId();
  const [state, dispatch] = useReducer(uploadFilesReducer, initialState);

  const filesToUpload: UploadFile[] = Object.values(state.filesToUpload);

  const pendingFilesToUpload: UploadFile[] = filesToUpload.filter((f) => f.status === UploadFileStatus.Pending);

  const startUpload = useCallback(
    (payload: UploadFile) =>
      dispatch({
        type: UploadActionType.StartUpload,
        payload,
      }),
    [dispatch],
  );

  const uploadSuccess = useCallback(
    (payload: UploadFile) =>
      dispatch({
        type: UploadActionType.UploadSuccess,
        payload,
      }),
    [dispatch],
  );

  const uploadError = useCallback(
    (payload: { file: UploadFile; error: ErrorType }) => dispatch(actions.uploadError(payload)),
    [dispatch],
  );

  useEffect(() => {
    if (pendingFilesToUpload.length > 0) {
      Promise.resolve(
        pendingFilesToUpload.map(async (file) => {
          startUpload(file);

          try {
            const fileName = file.path.includes('/')
              ? new FileName(file.path.substring(file.path.lastIndexOf('/') + 1))
              : new FileName(file.path);

            await FileSystem.uploadFile(file, fileName, currentMigrationId || '');
            uploadSuccess(file);
          } catch (err) {
            const e = err as any;
            const errorMsg = e.response?.data?.message || e.message || '';
            uploadError({ file, error: errorMsg });
          }
        }),
      )
        .then(() => {
          log.info('Complete');
        })
        .catch((error) => {
          log.error(error);
        });
    }
  }, [pendingFilesToUpload, startUpload, uploadSuccess, uploadError, currentMigrationId]);

  const uploadFiles = useCallback(
    (payload: FileWithPath[]) =>
      dispatch({
        type: UploadActionType.UploadFiles,
        payload,
      }),
    [dispatch],
  );

  const cancelUpload = useCallback(
    (payload: UploadFile) =>
      dispatch({
        type: UploadActionType.CancelUpload,
        payload,
      }),
    [dispatch],
  );

  const retryUpload = useCallback(
    (payload: UploadFile) =>
      dispatch({
        type: UploadActionType.RetryUpload,
        payload,
      }),
    [dispatch],
  );

  return (
    <UploadFilesContext.Provider
      value={{
        cancelUpload,
        retryUpload,
        filesToUpload,
        uploadFiles,
      }}
    >
      {children}
    </UploadFilesContext.Provider>
  );
};

const useUploadFiles = () => {
  const context = React.useContext(UploadFilesContext);

  if (context === undefined) {
    throw new Error('useUploadFiles must be used within a UploadFilesContext');
  }

  return context;
};

export { useUploadFiles, UploadFilesProvider };
