import { z } from 'zod';

export const MigrationSchema = z.object({
  migrationName: z.string().min(5, 'Please enter at least 5 characters'),
  changeControlStatement: z.string().min(20, 'Please enter at least 20 characters'),
});

export type CreateMigration = {
  type: 'create';
  migrationName: string;
  changeControlStatement: string;
};
export type UpdateMigration = {
  type: 'update';
  id: string;
  migrationName: string;
  changeControlStatement: string;
};
