import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ListMigrations, MigrationContainer } from './views';
import MigrationProvider from './providers/MigrationProvider';

export const App: React.FC = () => {
  return (
    <main className="mm__app">
      <MigrationProvider>
        <Routes>
          <Route path="/" element={<ListMigrations />} />
          <Route path="/:id" element={<MigrationContainer />} />
        </Routes>
      </MigrationProvider>
    </main>
  );
};
