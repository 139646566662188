import {
  Button,
  QButton,
  QIconButton,
  QText,
  StopOutlined,
  Text,
  useCurrentUser,
  WarningOutlined,
  QModal,
  QModalHeader,
  QModalBody,
  QModalActions,
  QBox,
} from '@qualio/ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { contextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { Column, Row, useExpanded, useGroupBy, useRowSelect, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { useTemplates } from '../../../hooks/Templates';
import { ApiTemplate } from '../../../models';
import { MigrationFile, useMigrationFiles } from '../../../providers';
import { PERMISSIONS } from '../../../types/UserPermissions';
import styles from './modules/table.module.css';

export const DOCUMENT_MENU_ID = 'document-id';

export const Table: React.FC<{
  columns: Column<any>[];
  data: any[];
  reorderData: (rowId: string, placesMoved: number) => MigrationFile[];
  sortData: (templateVal: string) => MigrationFile[];
}> = ({ columns, data, reorderData, sortData }) => {
  const user = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [invalidFile, setInvalidFile] = useState({ fileName: '', errors: '' });

  const {
    templates: { data: templates },
    getDocumentCode,
  } = useTemplates();

  const { getDocumentsInTemplate, getAttachmentsInTemplate, getFileValidationErrors, setSelected, updateFile } =
    useMigrationFiles();

  const getRowId = React.useCallback((row) => row.id, []);

  const { getTableProps, getTableBodyProps, rows, prepareRow, toggleAllRowsExpanded } = useTable(
    {
      autoResetExpanded: false,
      data,
      columns,
      getRowId,
      initialState: {
        groupBy: ['template'],
        hiddenColumns: [
          'id',
          'templateID',
          'template',
          'templateKey',
          'doNotImport',
          'parentDocument',
          'hash',
          'isPlaceholder',
          'ordinal',
        ],
      },
    },
    useGroupBy,
    useExpanded,
    useRowSelect,
  );

  const { t } = useTranslation(['common', 'migrations']);

  const [rowState, setRowState] = useState<{
    [key: string]: { isSelected: boolean; height: number; index: number };
  }>({});
  const selected = rows
    .filter((row) => row.isSelected && row.values.id)
    .map((row) => row.values as unknown as MigrationFile);

  // fixed variable list has mistyped the ref object so we need to set as any to trick it.
  const listRef: any = React.createRef();

  useEffect(() => {
    setSelected(selected);
  }, [selected, setSelected]);

  useCallback(() => {
    setSelected(
      rows.filter((row) => row.isSelected && row.values.id).map((row) => row.values as unknown as MigrationFile),
    );
  }, [rows, setSelected]);

  React.useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded, data]);

  const handleRowClick = (ref: HTMLElement, id: string, index: number) => {
    if (rowState[id]) {
      setRowState({});
      (listRef.current as any).resetAfterIndex(index, false);
      return;
    }
    setRowState({ [id]: { height: 40, isSelected: true, index } });
  };

  const setRowHeight = useCallback(
    (ref: HTMLElement, selectedRowState: any, id: string) => {
      const height = ref.clientHeight;
      setRowState({
        ...rowState,
        [id]: { ...selectedRowState, ...{ height: height || 40 } },
      } as any);
    },
    [rowState],
  );

  const getWrapperStyleProps = (props: any, ignoreHeight = false) => {
    if (ignoreHeight) {
      return { ...props, height: 'auto' };
    }
    return props;
  };

  const handleDragEnd = async (result: any) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    const moved = destination.index - source.index;
    const reorderedData = reorderData(draggableId, moved);
    const updates = reorderedData.map((file) => ({
      fileId: file.id,
      properties: {
        ordinal: file.ordinal,
      },
    }));
    await updateFile(updates);
  };
  const getItemSize = (index: number) => {
    const { id } = rows[index];
    return rowState[id]?.height || 40;
  };
  const getRowProps = (row: Row<any>) => {
    const isHeaderRow = !row.values.id;
    const isAttachment = !!row.values.parentDocument;
    const canDrag = user.can(PERMISSIONS.CAN_PREPARE_MIGRATION) && !isAttachment;

    const onContextMenu: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (event) => {
      row.toggleRowSelected(true);
      if (!row.isGrouped) {
        contextMenu.show({ id: DOCUMENT_MENU_ID, event, props: row });
      }
    };

    const handleSortClick: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = () => {
      const sortedData = sortData(row.groupByVal);
      const updates = sortedData.map((file) => ({
        fileId: file.id,
        properties: {
          ordinal: file.ordinal,
        },
      }));
      void updateFile(updates);
    };

    const baseClassName = isAttachment
      ? 'mm__table__cell mm__table__cell--attachment'
      : row.isGrouped
      ? 'mm__table__cell mm__table--header'
      : 'mm__table__cell';

    const className = baseClassName + (row.isSelected && !isAttachment ? ' mm__table__cell--selected' : '');

    return {
      isHeaderRow,
      isAttachment,
      canDrag,
      onContextMenu,
      handleSortClick,
      className,
    };
  };
  const RenderRow = (
    row: Row<any>,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    style: any,
    rowProps: any,
    index = 0,
  ) => {
    useEffect(() => {
      if (!rowState[row.id]?.height && rowState[row.id]?.isSelected) {
        setRowHeight(rowRef!, { ...rowState[row.id] }, row.id);
        listRef.current.resetAfterIndex(rowState[row.id].index, false);
      }
    });
    let rowRef: HTMLElement | null;
    const { isHeaderRow, isAttachment, canDrag, onContextMenu, handleSortClick, className } = rowProps;
    const docsInTemplate = getDocumentsInTemplate(row.values.template);
    const attachmentsInTemplate = getAttachmentsInTemplate(row.values.template);
    const template = templates?.find((template: ApiTemplate) =>
      template.key && row.values.templateKey
        ? template.key === row.values.templateKey
        : template.id && row.values.templateID
        ? template.id === row.values.templateID
        : template.name === row.values.template,
    );
    const rowGroupProps = {
      ...row.getRowProps({
        style: {
          ...style,
        },
      }),
    };
    const errors = getFileValidationErrors(row.id);

    const errorTooltip = errors.map((v) => `• ${v.error}`).join('\n');

    const classes = className + ` ${styles['col-xs-1']}`;

    const handleShowErrorPopup = (fileName: string, errors: string) => {
      setInvalidFile({ fileName, errors });
      setIsOpen(true);
    };

    // // Set the number of documents and attachments labels
    if (isHeaderRow) {
      return (
        <div
          {...provided.draggableProps}
          {...rowGroupProps}
          style={getWrapperStyleProps(
            { ...rowGroupProps.style, ...provided.draggableProps.style },
            !rowState[row.id]?.height && rowState[row.id]?.isSelected,
          )}
          ref={provided.innerRef}
          onContextMenu={onContextMenu}
          className={'flex'}
        >
          <span
            ref={(node) => (rowRef = node)}
            onClick={() => handleRowClick(rowRef!, row.id, index)}
            className={`${styles.row} flex w-100`}
          >
            <div className={`${className} ${styles['col-xs-4']}`} style={{ display: 'flex' }}>
              <QText fontSize="sm">
                {row.groupByVal} ({t('migrations:lblNumDocuments', { count: docsInTemplate })}
                {attachmentsInTemplate > 0
                  ? t('migrations:lblNumAttachments', {
                      count: attachmentsInTemplate,
                    })
                  : null}
                )
              </QText>
              <QIconButton
                aria-label="sort-icon-button"
                onClick={handleSortClick}
                iconName="ChevronUp"
                size="xs"
              ></QIconButton>
            </div>

            <div className={className + ` ${styles['col-xs-2']}`}>
              <QText fontSize="sm">{t('migrations:lblExtractContent')}</QText>
            </div>
            <div className={classes}>
              <QText fontSize="sm">{t('migrations:lblBulkApprove')}</QText>
            </div>
            <div className={className + ` ${styles['col-xs-2']}`}>
              <QText fontSize="sm">{t('migrations:lblApprovers')}</QText>
            </div>
            <div className={className + ` ${styles['col-xs']}`} style={{ justifyContent: 'center' }}>
              <QText fontSize="sm">{t('migrations:lblOwners')}</QText>
            </div>
            <div className={className + ` ${styles['end-xs']}`}></div>
          </span>
        </div>
      );
    }

    const fileNameCell = row.cells.find((c) => c.column.Header === 'fileName');

    // Set labels that cannot render inline
    const lblDoNotMigrate = t('migrations:lblDoNotMigrate');

    return (
      <div
        {...provided.draggableProps}
        {...rowGroupProps}
        style={getWrapperStyleProps(
          { ...rowGroupProps.style, ...provided.draggableProps.style },
          !rowState[row.id]?.height && rowState[row.id]?.isSelected,
        )}
        ref={provided.innerRef}
        onContextMenu={onContextMenu}
        className={'flex'}
      >
        <span
          ref={(node) => (rowRef = node)}
          className={`${styles.row} flex w-100`}
          onClick={() => handleRowClick(rowRef!, row.id, index)}
        >
          <div className={`${className}  ${styles['col-xs-4']} center`}>
            <span className="flex w-100">
              {!isAttachment ? (
                <span className={`${styles['col-xs-1']} flex`}>
                  {canDrag ? (
                    <div {...provided.dragHandleProps} className={`mm__table__combined_cell mm__table__cell--dragger`}>
                      <span
                        style={{
                          letterSpacing: '-3px',
                          color: '#b6b7c2',
                        }}
                      >
                        &#8942;&#8942;
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <QBox ml="0.1em" mt="0.2em">
                    <QButton
                      size="xs"
                      data-testid="file-select-button"
                      onClick={() => row.toggleRowSelected()}
                      leftIcon={row.isSelected ? 'CheckSquare' : 'Square'}
                      variant="link"
                    >
                      &nbsp;
                    </QButton>
                  </QBox>
                </span>
              ) : null}
              <div className={`${styles['col-xs']}`} style={{ whiteSpace: 'nowrap' }}>
                <label htmlFor={`${row.id}-checkbox`}>
                  <Text size="sm">
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {errors.length > 0 && (
                        <WarningOutlined
                          data-testid="file-validation-warning"
                          onClick={() => handleShowErrorPopup(fileNameCell?.value, errorTooltip)}
                          style={{
                            color: 'red',
                            marginRight: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                      {row.original.doNotImport ? (
                        <StopOutlined
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                            marginRight: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          title={lblDoNotMigrate}
                        />
                      ) : undefined}
                      {!snapshot.isDragging && !isAttachment && getDocumentCode(row.original, template)}
                    </span>
                  </Text>
                </label>
              </div>
              <div
                key={`${fileNameCell!.row.id}-${fileNameCell!.column.Header}-${fileNameCell!.value}`}
                className={`${styles['col-xs-8']} ${
                  isAttachment ? `mm__table__cell--attachment-name ${styles['col-xs-offset-1']}` : ''
                } overflow-hidden ellipsis`}
              >
                {fileNameCell!.render('Cell', {
                  dragHandleProps: provided.dragHandleProps,
                  isDragging: snapshot.isDragging,
                })}
              </div>
            </span>
          </div>

          {row.cells.map((cell) => {
            const isFileName = cell.column.Header === 'fileName';
            if (isFileName) {
              return [];
            }
            const getAdditionalClass = (header: string | undefined): string => {
              if (!header) {
                return '';
              }
              if (header.toLowerCase() === 'owner') {
                return `${styles['col-xs']}`;
              }
              if (header.toLowerCase() === 'content' || header.toLowerCase() === 'approvers') {
                return `${styles['col-xs-2']}`;
              } else return `${styles['col-xs-1']}`;
            };
            return (
              <div
                key={`${cell.row.id}-${cell.column.Header}-${cell.value}`}
                className={`${className} ${getAdditionalClass(cell.column.Header?.toString())}`}
              >
                {cell.render('Cell', {
                  dragHandleProps: provided.dragHandleProps,
                  isDragging: snapshot.isDragging,
                  isRowSelected: !!rowState[row.id]?.isSelected,
                })}
              </div>
            );
          })}
          <div className={className + ` ${styles['end-xs']}`}>
            <Button onClick={onContextMenu} type="text">
              ...
            </Button>
          </div>
        </span>
      </div>
    );
  };
  const RenderDraggableRow = ({ index, style }: { index: number; style: any }) => {
    const row = rows[index];
    prepareRow(row);
    const rowProps = getRowProps(row);

    return (
      <Draggable
        draggableId={row.id}
        key={row.id}
        index={index}
        isDragDisabled={rowProps.isHeaderRow || !rowProps.canDrag}
      >
        {(provided, snapshot) => {
          return RenderRow(row, provided, snapshot, style, rowProps, index);
        }}
      </Draggable>
    );
  };
  if (rows.length < data.length) return null;

  return (
    <React.Fragment>
      <div {...getTableProps()} className="mm__table">
        <DragDropContext onDragEnd={(result) => void handleDragEnd(result)}>
          <Droppable
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
              const row: any = rows[rubric.source.index];
              prepareRow(row);
              const rowProps = getRowProps(row);
              return RenderRow(row, provided, snapshot, {}, rowProps);
            }}
            droppableId="table-body"
          >
            {(provided) => (
              <div ref={provided.innerRef} className="auto-sizer-parent">
                <AutoSizer>
                  {({ height, width }) => (
                    <VariableSizeList
                      {...provided.droppableProps}
                      {...getTableBodyProps()}
                      height={height}
                      itemCount={rows.length}
                      itemSize={getItemSize}
                      width={width}
                      ref={listRef}
                    >
                      {RenderDraggableRow}
                    </VariableSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <QModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <QModalHeader>{invalidFile?.fileName || 'Unknown'}</QModalHeader>
        <QModalBody>
          <div style={{ whiteSpace: 'pre-line' }}>{invalidFile?.errors || '-'}</div>
        </QModalBody>
        <QModalActions>
          <QButton onClick={() => setIsOpen(false)}>Close</QButton>
        </QModalActions>
      </QModal>
    </React.Fragment>
  );
};
