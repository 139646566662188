import { QButton, QText, QTag, QBox, QDivider } from '@qualio/ui-components';
import React, { useCallback, useState, useContext } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UploadFileStatus, useUploadFiles } from '../../../providers';
import { PERMISSIONS } from '../../../types/UserPermissions';
import { FileInput } from './FileUpload';
import { ProgressDialog } from './ProgressDialog';
import { getUploadStatusTagType } from '../../../utils/getUploadStatusTagType';
import MigrationContext from '../../../providers/MigrationContext';

type UploadProps = {
  onNext: () => void;
  onCancel: () => void;
};

export const UploadFiles: React.FC<UploadProps> = (props) => {
  const { onNext, onCancel } = props;
  const { t } = useTranslation(['common', 'migrations']);
  const { user } = useContext(MigrationContext);
  const { uploadFiles, cancelUpload, retryUpload, filesToUpload } = useUploadFiles();
  const [isLoading, setIsLoading] = useState(false);

  const onFilesAccepted = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      // Filter out file duplicates that have the same name, last modified date, and size
      const fileMap = new Set<string>();
      const filteredFiles: FileWithPath[] = [];
      for (const file of acceptedFiles) {
        const mapKey = file.name + file.lastModified + file.size;
        if (!fileMap.has(mapKey)) {
          filteredFiles.push(file);
          fileMap.add(mapKey);
        }
      }
      return uploadFiles(filteredFiles);
    },
    [uploadFiles],
  );

  const fileUploadDisabled = () => user.cannot(PERMISSIONS.CAN_UPLOAD_MIGRATION_FILES);

  const filesList = filesToUpload.map((file) => {
    return (
      <li key={file.path} className="flex--space-between">
        <div className="flex">
          <div className="StatusTag">
            <QTag variantColor={getUploadStatusTagType(UploadFileStatus[file.status])}>
              {UploadFileStatus[file.status]}
            </QTag>
          </div>
          <div className="UploadRow">
            <QText fontSize="sm">{file.file.name}</QText>
            {file.error && (
              <QText fontSize="sm" color="red.800" marginLeft={4}>
                {file.error.message || file.error}
              </QText>
            )}
          </div>
        </div>
        <QBox>
          {file.status === UploadFileStatus.Pending && (
            <QButton
              onClick={(e) => {
                e.stopPropagation();
                cancelUpload(file);
              }}
            >
              {t('lblCancel')}
            </QButton>
          )}
          {file.status === UploadFileStatus.Error && (
            <QBox display="flex" flexWrap="nowrap" gap={1}>
              <QButton
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                  retryUpload(file);
                }}
              >
                {t('lblRetry')}
              </QButton>
              <QButton
                variant="outline"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelUpload(file);
                }}
              >
                {t('lblCancel')}
              </QButton>
            </QBox>
          )}
        </QBox>
      </li>
    );
  });

  // const noFiles = filesToUpload.length < 1 || filesToUpload.some((file) => file.status !== UploadFileStatus.Success);

  const handleNext = async () => {
    setIsLoading(true);
    await onNext();
    setIsLoading(false);
  };

  const cannotUpload = user.cannot(PERMISSIONS.CAN_UPLOAD_MIGRATION_FILES);

  return (
    <QBox display="flex" width="full" flexDirection="column">
      <QBox mb={3}>{filesToUpload.length > 0 ? <ProgressDialog filesToUpload={filesToUpload} /> : null}</QBox>
      <FileInput onFilesAccepted={onFilesAccepted} disabled={fileUploadDisabled()}>
        <QText>{t('migrations:lblDragNDrop')}</QText>
      </FileInput>
      <QBox mt={3} maxHeight="300px" overflowX="scroll">
        {filesList}
      </QBox>
      <QDivider my={3} />
      <QBox display="flex" justifyContent="space-between">
        <QButton variant="outline" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton onClick={handleNext} isLoading={isLoading} isDisabled={cannotUpload}>
          Next
        </QButton>
      </QBox>
    </QBox>
  );
};
