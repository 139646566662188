import { useCurrentUser } from '@qualio/ui-components';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { environment } from '../environments/environment';
import { authenticate } from '../services/axios';
import { CACHE_KEYS } from '../utils/react-query';

type AuthContainerProps = {
  children: JSX.Element;
};

export const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { companyId } = useCurrentUser();

  // use 'beaker.session.id' cookie to obtain 'QualioUserToken' JWT cookie
  // (and refresh the cookie on an interval)
  useQuery([CACHE_KEYS.AUTHENTICATION, companyId], () => authenticate(companyId), {
    refetchInterval: environment.authSuccessRefreshIntervalMillis,
    onSuccess: () => {
      setIsAuthenticated(true);
      setIsLoading(false);
    },
    onError: () => {
      setIsAuthenticated(false);
      setIsLoading(false);
    },
  });

  if (isLoading) {
    return <span>Loading</span>;
  }
  if (!isAuthenticated) {
    window.location.replace(environment.loginUrl);
  }
  return children;
};
