import { Routes } from '../api-routes';
import { MigrationStatus } from '../models';
import { MigrationDTO } from '../models/data-transfer-objects/migration-dto';
import { MigrationSummaryDTO } from '../models/data-transfer-objects/migration-summary-dto';
import { apiClient } from './axios';

export const MigrationsApi = {
  fetchMigrations: async (companyId: number) => {
    const { data } = await apiClient.get<{ migrations: MigrationSummaryDTO[] }>(`company/${companyId}/migrations`);
    return data.migrations;
  },
  fetchMigration: async (migrationId?: string) => {
    if (!migrationId) throw new Error('Migration ID not defined');

    const { data } = await apiClient.get<MigrationDTO>(`${Routes.Migrations}/${migrationId}`);
    return data;
  },
  createMigration: async (
    companyId: number,
    migrationName: string,
    changeControlStatement: string,
    onSuccess: any,
    onError: any,
  ) => {
    try {
      await apiClient.post<{ migrations: MigrationSummaryDTO[] }>(`company/${companyId}/migrations`, {
        migrationName,
        changeControlStatement,
      });
      onSuccess();
    } catch (error: any) {
      if (onError) {
        onError(error);
      }
    }
  },
  updateMigration: async (
    {
      id,
      migrationName,
      changeControlStatement,
    }: {
      id: string;
      migrationName: string;
      changeControlStatement: string;
    },
    onSuccess: any,
    onError: any,
  ) =>
    await apiClient
      .put<MigrationSummaryDTO>(`${Routes.Migrations}/${id}`, {
        migrationName,
        changeControlStatement,
      })
      .then(onSuccess)
      .catch((e) => {
        if (onError) onError(e);
      }),
  completeMigration: async (id: string) => await apiClient.post(`${Routes.Migrations}/${id}/complete`),
  redoMigration: async (id: string) => {
    const { data } = await apiClient.post(`${Routes.Migrations}/${id}/redo`);
    return data;
  },
  startMigration: async ({ id }: MigrationDTO) => await apiClient.post(`${Routes.Migrations}/${id}/start`),
  resetMigration: async ({ id }: MigrationDTO) => await apiClient.post(`${Routes.Migrations}/${id}/reset`),
  getIndexDownloadUrl: async ({ id }: MigrationDTO) => await apiClient.get<string>(`${Routes.Migrations}/${id}/index`),
  updateStatus: async (migrationId: string, newStatus: MigrationStatus) =>
    await apiClient.patch<void>(`${Routes.Migrations}/${migrationId}`, {
      status: newStatus,
    }),
};
