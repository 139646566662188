import { FileWithPath } from 'react-dropzone';
import { ApiTag, ApiUser } from '../../models';
import { ErrorType } from './UploadFiles.types';

export * from './MigrationFiles.types';
export * from './UploadFiles.types';

export enum UploadFileStatus {
  Error,
  Pending,
  InProgress,
  Success,
}

export type UploadFile = {
  file: FileWithPath;
  path: string;
  migrationId?: string;
  template?: string;
  status: UploadFileStatus;
  error?: ErrorType;
};
export type MigrationFile = {
  id: string;
  ordinal?: number;
  migrationID: string;
  template: string;
  templateOrdinal?: number;
  templateID?: number;
  templateKey?: string;
  fileName: string;
  isDocumentContent: boolean;
  bulkApprove: boolean;
  approvers: ApiUser[];
  owner: ApiUser;
  parentDocument?: string;
  hash: string;
  isPlaceholder?: boolean;
  tags?: ApiTag[];
  doNotImport: boolean;
  documentCode?: string;
};

export type FileRecord<T> = Record<string, T>;
