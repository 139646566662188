import { QAlert, QButton, QDrawer, QText, QBox, QDivider } from '@qualio/ui-components';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import FileViewer from 'react-file-viewer';
import { useTranslation } from 'react-i18next';
import { environment } from '../../../environments/environment';
import { useMigrationSummaries } from '../../../hooks/Migrations';
import { ApiDocumentCode, ApiUser, ApiUserDTO } from '../../../models';
import { FileUpdate, MigrationFile, useMigrationFiles } from '../../../providers';
import { apiClient } from '../../../services';
import { PERMISSIONS } from '../../../types/UserPermissions';
import { sortFiles, sortTemplateFiles } from '../../../utils/sortDocuments';
import { DrawerStateAction, FileMenu } from './FileMenu';
import './PrepareMigration.css';
import { SelectApprovers } from './SelectApprovers';
import { SelectOwnerForm } from './SelectOwner';
import { Table } from './Table';
import MigrationContext from '../../../providers/MigrationContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FilenameCell } from '../shared/FilenameCell';
import { useTemplates } from '../../../hooks/Templates';

type PrepareMigrationProps = {
  onNext: () => void;
  onPrevious: () => void;
};

type MigrationFileRow<T> = {
  cell: {
    row: {
      id: string;
      values: MigrationFile;
    };
    value: T;
  };
};

export const PrepareMigration: React.FC<PrepareMigrationProps> = (props) => {
  const { t } = useTranslation(['common', 'migrations']);
  const { onNext, onPrevious } = props;
  const { state, fetchFiles, updateFile, downloadFile, selected } = useMigrationFiles();
  const [filePreview, setFilePreview] = useState<{ filePath: string; fileType: string } | undefined>(undefined);
  const [drawerState, setDrawerState] = useState<
    | {
        visible: true;
        action: DrawerStateAction.SelectOwner | DrawerStateAction.SelectApprovers;
        files: MigrationFile[];
      }
    | {
        visible: false;
      }
  >({ visible: false });
  const { data: migrationSummaries } = useMigrationSummaries();
  const { getCode } = useTemplates();
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const { user } = useContext(MigrationContext);
  const flags = useFlags();

  // Set labels that cannot render inline
  const lblPrepareErrorTitle = t('migrations:lblPrepareErrorTitle');
  const drawerTitle = t('lblPreview');

  const data = useMemo(() => {
    return sortFiles(Object.values(state.files));
  }, [state.files]);

  const hasLinkedAttachmentsCallback = useCallback(
    (fileId: string) => data.some(({ parentDocument }) => parentDocument === fileId),
    [data],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
      },
      {
        Header: 'hash',
        accessor: 'hash',
      },
      {
        Header: 'template',
        accessor: 'template',
      },
      {
        Header: 'templateID',
        accessor: 'templateID',
      },
      {
        Header: 'ordinal',
        accessor: 'ordinal',
      },
      {
        Header: 'parentDocument',
        accessor: 'parentDocument',
      },
      {
        Header: 'isPlaceholder',
        accessor: 'isPlaceholder',
      },
      {
        Header: 'fileName',
        accessor: 'fileName',
        Cell: ({ cell: { row, value } }: MigrationFileRow<string>) => {
          const {
            values: { id, isDocumentContent, parentDocument },
          } = row;
          return (
            <FilenameCell
              isFileUploadEnabled={flags.fileDocumentUpload}
              isLinkedAttachment={!!parentDocument}
              hasLinkedAttachments={hasLinkedAttachmentsCallback(id)}
              isDocumentContent={isDocumentContent}
              filename={value}
            />
          );
        },
      },
      {
        Header: 'doNotImport',
        accessor: 'doNotImport',
      },
      {
        Header: 'content',
        accessor: 'isDocumentContent',
        Cell: ({ cell: { row, value } }: MigrationFileRow<boolean>) => {
          if (row.values.parentDocument || row.values.isPlaceholder) {
            return null;
          }
          return (
            <QButton
              data-testid="file-content-button"
              isDisabled={user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION)}
              onClick={() =>
                void updateFile([
                  {
                    fileId: row.id,
                    properties: {
                      isDocumentContent: !value,
                    },
                  },
                ])
              }
              leftIcon={value ? 'Check' : 'Square'}
              variant="link"
            >
              &nbsp;
            </QButton>
          );
        },
      },
      {
        Header: 'bulkApprove',
        accessor: 'bulkApprove',
        Cell: ({ cell: { row, value } }: MigrationFileRow<boolean>) => {
          if (row.values.parentDocument || row.values.isPlaceholder) {
            return null;
          }
          return (
            <QButton
              data-testid="file-approve-button"
              isDisabled={user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION)}
              onClick={() =>
                void updateFile([
                  {
                    fileId: row.id,
                    properties: {
                      bulkApprove: !value,
                    },
                  },
                ])
              }
              rightIcon={value ? 'Check' : 'Square'}
              variant="link"
            >
              &nbsp;
            </QButton>
          );
        },
      },
      {
        Header: 'approvers',
        accessor: 'approvers',
        Cell: ({
          isRowSelected,
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value: ApiUser[];
            row: { values: { parentDocument: string } };
          };
          isRowSelected: boolean;
        }) => {
          if (parentDocument) {
            return null;
          }
          return (
            <div className="ApproversListSection">
              <div className="ApproversListShort">
                <QText key={value[0]?.email}>
                  {value[0]?.fullName} {value.length > 1 ? `+ [${value.length - 1}]` : null}
                </QText>
              </div>
              <div className="ApproversListFull">
                {value?.map((approver) => (
                  <QText key={approver.email}>{approver.fullName}</QText>
                ))}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'owner',
        accessor: 'owner',
        Cell: ({
          cell: {
            value,
            row: {
              values: { parentDocument },
            },
          },
        }: {
          cell: {
            value?: ApiUser;
            row: { values: { parentDocument: string } };
          };
        }) => (!parentDocument && value ? <div className="OwnerListName">{value.fullName}</div> : null),
      },
    ],
    [flags.fileDocumentUpload, updateFile, user, hasLinkedAttachmentsCallback],
  );

  const [approvers, setApprovers] = useState<ApiUser[]>([]);
  const [owners, setOwners] = useState<ApiUser[]>([]);

  const fetchUsers = useCallback(() => {
    return apiClient
      .get(`${environment.PUBLIC_API}/v2/${user.companyId}/users?company_id=${user.companyId}`)
      .then(({ data }) => {
        const _approvers: ApiUser[] = data
          .filter(({ permissions }: ApiUserDTO) => permissions.includes('can_approve_doc'))
          .map(
            ({ permissions, full_name, email, invite_status }: ApiUserDTO) =>
              ({
                fullName: full_name,
                email,
                invite_status,
                isQM: permissions.includes('can_change_doc_owner'),
              } as ApiUser),
          );
        const _owners: ApiUser[] = data
          .filter(({ permissions }: ApiUserDTO) => permissions.includes('can_author_doc'))
          .map(
            ({ full_name, email, invite_status }: ApiUserDTO) =>
              ({
                fullName: full_name,
                email,
                invite_status,
              } as ApiUser),
          );

        setOwners(_owners);
        setApprovers(_approvers);
      });
  }, [user.companyId]);

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([fetchFiles(), fetchUsers()]);
    };
    void fetch();
  }, [fetchFiles, fetchUsers]);

  const { loading, errors } = state;

  const hideDrawer = () => setDrawerState({ visible: false });
  const showDrawer = (
    action: DrawerStateAction.SelectOwner | DrawerStateAction.SelectApprovers,
    files: MigrationFile[],
  ) =>
    setDrawerState({
      visible: true,
      action,
      files,
    });

  const previewFile = async (file: MigrationFile) => {
    setFilePreview({
      filePath: (await downloadFile(file.hash)) ?? '',
      fileType: file.fileName.split('.').pop() ?? '',
    });
  };

  const updateOwner = async (owner: ApiUser) => {
    if (drawerState.visible) {
      await updateFiles('owner', owner, drawerState.files);
    }
  };

  const updateApprovers = async (approvers: ApiUser[]) => {
    if (drawerState.visible) {
      await updateFiles('approvers', approvers, drawerState.files);
    }
  };

  const updateFiles = async (
    property: keyof MigrationFile,
    value: string | boolean | ApiUser | ApiUser[] | null,
    files: MigrationFile[],
  ) => {
    const updates: FileUpdate[] = files.map((original) => ({
      fileId: original.id,
      properties: {
        [property]: value,
      },
    }));
    await updateFile(updates);
  };

  const ensureIndexIsWithinTemplateBounds = (potentialIndex: number, lowerBound: number, upperBound: number) => {
    let finalIndex = 0;
    if (potentialIndex < lowerBound) {
      finalIndex = lowerBound;
    } else if (potentialIndex > upperBound) {
      finalIndex = upperBound;
    } else {
      finalIndex = potentialIndex;
    }
    return finalIndex;
  };

  const findNextAvailableIndex = useCallback((newData: MigrationFile[], index: number): number => {
    if (newData[index]?.parentDocument) {
      return findNextAvailableIndex(newData, index + 1);
    }
    return index;
  }, []);

  const getSelectedOwner = () => {
    // there is no way to cleanly reflect the potential bulk update of several files that could have different approvers
    if (selected.length > 1) {
      return null;
    }
    return selected[0]?.owner;
  };

  const getSelectedApprovers = () => {
    // there is no way to cleanly reflect the potential bulk update of several files that could have different approvers
    if (selected.length > 1) {
      return [];
    }
    return selected.flatMap((s: { approvers: any }) => s.approvers);
  };

  const sortTemplateSection = useCallback(
    (templateVal: string, dataToUse = data): MigrationFile[] => {
      return sortTemplateFiles(templateVal, dataToUse);
    },
    [data],
  );

  const reorderData = useCallback(
    (rowId: string, placesMoved: number, dataToUse = data): MigrationFile[] => {
      const newData = [...dataToUse];
      const movedIndex = newData.findIndex((data) => data.id === rowId);
      const movedFileAttachments = newData.filter(({ parentDocument }) => parentDocument === rowId);
      const numberOfItemsToDelete = 1 + movedFileAttachments.length;
      const [movedRow] = newData.splice(movedIndex, numberOfItemsToDelete);
      const firstFileIndex = newData.findIndex((data) => data.template === movedRow.template);
      const firstIndexOfNewTemplate = newData.findIndex(
        (data, index) => index > firstFileIndex && data.template !== newData[firstFileIndex].template,
      );
      // if firstIndexOfNewTemplate is -1 then you are sorting the last template group of the array
      const lastFileIndex = firstIndexOfNewTemplate !== -1 ? firstIndexOfNewTemplate : newData.length;
      const newIndex = movedIndex + placesMoved;
      const finalIndex = ensureIndexIsWithinTemplateBounds(newIndex, firstFileIndex, lastFileIndex);

      newData.splice(findNextAvailableIndex(newData, finalIndex), 0, movedRow, ...movedFileAttachments);

      return newData.map((item, index) => {
        return {
          ...item,
          ordinal: index,
        };
      });
    },
    [data, findNextAvailableIndex],
  );

  const setDrawerStateTitle = (drawerStateAction: DrawerStateAction): string => {
    if (drawerStateAction === DrawerStateAction.SelectOwner) {
      return t('files:lblSelectOwnerModalTitle');
    }

    if (drawerStateAction === DrawerStateAction.SelectApprovers) {
      return t('files:lblSelectApproversModalTitle');
    }

    return '';
  };

  const cannotUpload = user.cannot(PERMISSIONS.CAN_UPLOAD_MIGRATION_FILES);
  const cannotPrepare = user.cannot(PERMISSIONS.CAN_PREPARE_MIGRATION);

  const handlePrevious = async () => {
    setIsPreviousLoading(true);
    await onPrevious();
    setIsPreviousLoading(false);
  };

  const getDocumentCode = (file: MigrationFile, template?: ApiDocumentCode) => {
    if (template) {
      return `${template.templatePrefix}${template.code + (file.templateOrdinal || 0)}`;
    }
    return 'Unassigned';
  };

  const updateFilesWithIntendedDocumentCode = async () => {
    const filesWithIntendedDocumentCodeUpdates = data.map((file) => {
      const code = getCode(file.templateID || 0)?.data;
      const documentCode = getDocumentCode(file, code);
      return {
        fileId: file.id,
        properties: {
          documentCode,
        },
      };
    });
    await updateFile(filesWithIntendedDocumentCodeUpdates);
  };

  const handleNext = async () => {
    setIsNextLoading(true);
    await updateFilesWithIntendedDocumentCode();
    await onNext();
    setIsNextLoading(false);
  };

  if (loading) {
    return <QText>{t('lblLoading')}</QText>;
  }

  return (
    <QBox>
      <div className="flex--column flex--full">
        {errors?.length ? (
          <QAlert status="error" title={lblPrepareErrorTitle} description={t('migrations:lblPrepareErrorMessage')} />
        ) : undefined}
        {data.length > 0 && (
          <QBox>
            <div className="flex--reverse">
              <a href="/doc-templates/new" target="_blank" className="link--no-op">
                <QButton variant="link" rightIcon="ExternalLink">
                  {t('migrations:lblCreateTemplate')}
                </QButton>
              </a>
            </div>
            <Table columns={columns} data={data} reorderData={reorderData} sortData={sortTemplateSection} />
            <FileMenu
              migrationSummaries={migrationSummaries ?? []}
              data={data}
              previewFile={previewFile}
              selected={selected}
              showDrawer={showDrawer}
              reorderData={reorderData}
            />
          </QBox>
        )}
        <QDivider my={3} />
        <QBox display="flex" justifyContent="space-between">
          <QBox display="flex">
            <QButton
              variant="outline"
              onClick={() => void handlePrevious()}
              isLoading={isPreviousLoading}
              isDisabled={cannotUpload}
            >
              Previous
            </QButton>
            <QText marginLeft={2} fontSize="xs">
              {t('migrations:lblForgotAFile')}
            </QText>
          </QBox>
          <QBox display="flex">
            <QText marginRight={2} fontSize="xs">
              {t('migrations:lblClickNext')}
            </QText>
            <QButton onClick={() => void handleNext()} isLoading={isNextLoading} isDisabled={cannotPrepare}>
              Next
            </QButton>
          </QBox>
        </QBox>
      </div>

      <QDrawer
        size="xl"
        title={drawerState.visible ? setDrawerStateTitle(drawerState.action) : ''}
        isOpen={drawerState.visible}
        onClose={hideDrawer}
      >
        {drawerState.visible && drawerState.action === DrawerStateAction.SelectOwner && (
          <SelectOwnerForm
            onClose={hideDrawer}
            owners={owners}
            onSubmit={(owner) => void updateOwner(owner)}
            preSelectedOwner={getSelectedOwner()}
          />
        )}
        {drawerState.visible && drawerState.action === DrawerStateAction.SelectApprovers && (
          <SelectApprovers
            onClose={hideDrawer}
            approvers={approvers}
            onSubmit={(approvers) => void updateApprovers(approvers)}
            preSelectedApprovers={getSelectedApprovers()}
          />
        )}
      </QDrawer>

      {filePreview && (
        <QDrawer isOpen={!!filePreview} onClose={() => setFilePreview(undefined)} title={drawerTitle}>
          <FileViewer {...filePreview} />
        </QDrawer>
      )}
    </QBox>
  );
};
