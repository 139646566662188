import { firstBy } from 'thenby';
import { MigrationFile } from '../providers';

export const sortFiles = (files: MigrationFile[]) => {
  let currTemplate = '';
  let tIdx = 0;
  let attCount = 0;
  let newSort: MigrationFile[] = [];
  files.sort(firstBy('ordinal')).forEach((file) => {
    if (currTemplate !== file.template) {
      tIdx = 0;
      attCount = 0;
      currTemplate = file.template;
    }
    if (file.parentDocument) {
      attCount++;
    }
    newSort = [...newSort, { ...file, templateOrdinal: tIdx++ - attCount }];
  });
  return newSort;
};

// Sort files in a section alphabetically
export const sortTemplateFiles = (templateVal: string, files: any) => {
  const dataCopy = [...files];
  const templateFiles = dataCopy.filter(({ template }) => template === templateVal);

  // Sort parent files by filename, case insensitive
  const parentTemplateFiles = templateFiles.filter(({ parentDocument }) => parentDocument === '');
  const newData = parentTemplateFiles.sort((a, b) =>
    a.fileName.localeCompare(b.fileName, undefined, {
      sensitivity: 'base',
    }),
  );

  // Get list of attachments and splice it in new list
  const attachmentTemplateFiles = templateFiles.filter(({ parentDocument }) => parentDocument !== '');
  attachmentTemplateFiles.forEach(function (attachment) {
    const parentIndex = newData.findIndex((file) => {
      return file.id === attachment.parentDocument;
    });
    newData.splice(parentIndex + 1, 0, attachment);
  });

  // Set the new ordinal values, starting with previous lowest
  let currentOrdinal = Math.min(
    ...templateFiles.map(function (o) {
      return o.ordinal;
    }),
  );
  newData.forEach(function (file) {
    file.ordinal = currentOrdinal;
    currentOrdinal++;
  });

  return newData;
};

export const resetFileOrdinals = (files: MigrationFile[]) => {
  let newOrdinal = 0;
  let newSort: MigrationFile[] = [];
  files.sort(firstBy('template').thenBy('ordinal')).forEach((file) => {
    newSort = [...newSort, { ...file, ordinal: newOrdinal }];
    newOrdinal++;
  });
  return newSort;
};

export const initialSorting = (files: MigrationFile[]) => {
  let filesWithParentDocument: Record<string, MigrationFile[]> = {};
  const filesWithoutParentDocument: MigrationFile[] = files.filter((file) => {
    if (file.parentDocument) {
      const existingFiles = filesWithParentDocument[file.parentDocument] || [];
      const newFiles = [...existingFiles, file];
      filesWithParentDocument = {
        ...filesWithParentDocument,
        [file.parentDocument]: newFiles,
      };
      return false;
    }
    return true;
  });

  let newSort: MigrationFile[] = [];
  let index = 0;
  filesWithoutParentDocument.sort(firstBy('template').thenBy('fileName')).forEach((file) => {
    newSort = [...newSort, { ...file, ordinal: index++ }];
    if (filesWithParentDocument[file.id]) {
      newSort = [
        ...newSort,
        ...filesWithParentDocument[file.id].map((f) => ({
          ...f,
          ordinal: index++,
        })),
      ];
    }
  });

  return newSort;
};
