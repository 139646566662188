import React from 'react';
import { QBox, QTag } from '@qualio/ui-components';
import { MigrationStatus } from '../../../../models/enums';
import { useTranslation } from 'react-i18next';

type TagProps = {
  variantColor: any;
  statusLabel: string;
};

// Available colors
// 'default' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' | 'purple' | 'pink' | 'gray';
const getStatusLabelProps = (status: number, t: (key: string) => string): TagProps => {
  let statusLabel = t('migrations:lblStatusDraft');
  let variantColor = 'default';

  switch (status) {
    case MigrationStatus.DRAFT:
      statusLabel = t('migrations:lblStatusDraft');
      variantColor = 'default';
      break;
    case MigrationStatus.PREPARING:
      statusLabel = t('migrations:lblStatusPreparing');
      variantColor = 'blue';
      break;
    case MigrationStatus.IN_REVIEW:
      statusLabel = t('migrations:lblStatusInReview');
      variantColor = 'purple';
      break;
    case MigrationStatus.SCHEDULED:
      statusLabel = t('migrations:lblStatusScheduled');
      variantColor = 'yellow';
      break;
    case MigrationStatus.IN_PROGRESS:
      statusLabel = t('migrations:lblStatusInProgress');
      variantColor = 'orange';
      break;
    case MigrationStatus.COMPLETE:
      statusLabel = t('migrations:lblStatusComplete');
      variantColor = 'green';
      break;
    case MigrationStatus.ERROR:
      statusLabel = t('migrations:lblStatusError');
      variantColor = 'red';
      break;
  }

  return {
    statusLabel: statusLabel,
    variantColor: variantColor,
  };
};
export interface StatusTagProps {
  status: number;
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const { t } = useTranslation(['migrations']);
  const { statusLabel, variantColor } = getStatusLabelProps(status, t);

  return (
    // Wrapping the QTag in a QBox prevents the tag from resizing vertically with its parent.
    <QBox>
      <QTag variantColor={variantColor}>{statusLabel}</QTag>
    </QBox>
  );
};
