import { FileWithPath } from 'react-dropzone';
import { UploadFile } from '.';

export enum UploadActionType {
  CancelUpload,
  RetryUpload,
  StartUpload,
  UploadFiles,
  UploadError,
  UploadSuccess,
}

export type ErrorType = {
  key: string;
  message: string;
};

export interface CancelUpload {
  type: UploadActionType.CancelUpload;
  payload: UploadFile;
}
export interface RetryUpload {
  type: UploadActionType.RetryUpload;
  payload: UploadFile;
}
export interface StartUpload {
  type: UploadActionType.StartUpload;
  payload: UploadFile;
}
export interface UploadError {
  type: UploadActionType.UploadError;
  payload: {
    file: UploadFile;
    error: ErrorType;
  };
}
export interface UploadFiles {
  type: UploadActionType.UploadFiles;
  payload: FileWithPath[];
}
export interface UploadSuccess {
  type: UploadActionType.UploadSuccess;
  payload: UploadFile;
}

export type UploadActions = CancelUpload | RetryUpload | StartUpload | UploadError | UploadFiles | UploadSuccess;
