import React from 'react';
import { QButton, QDataRow, QDataTable, QTable, QTbody, QTd, QText, QTr } from '@qualio/ui-components';
import { useTranslation } from 'react-i18next';
import { MigrationSummaryDTO } from '../../../../models/data-transfer-objects/migration-summary-dto';
import { StatusTag } from './StatusTag';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const DEFAULT_PAGE_SIZE = 10;

type MigrationsTableProps = {
  migrations: MigrationSummaryDTO[];
  showMigrationForm: (id?: string, name?: string, changeControlStatement?: string) => void;
  migrationEditButtonAllowed: (status: number) => boolean;
  migrationViewButtonAllowed: (status: number) => boolean;
};

const getFormattedDate = (date: Date) => {
  return date ? `${new Date(date).toLocaleDateString()}-${new Date(date).toLocaleTimeString()}` : '';
};

const getColumns = (t: (key: string) => string) => [
  {
    Header: 'Migration Name',
    accessor: 'name',
    width: '35%',
    Cell: (props: any) => <QText>{props.value}</QText>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '15%',
    Cell: (props: any) => <StatusTag status={props.value} />,
  },
  {
    Header: 'Created',
    accessor: 'createdDate',
    width: '15%',
    Cell: (props: any) => <QText>{getFormattedDate(props.value)}</QText>,
  },
  {
    Header: 'Completed',
    accessor: 'completionDate',
    width: '15%',
    Cell: (props: any) => <QText>{getFormattedDate(props.value)}</QText>,
  },
  {
    Header: '',
    accessor: 'view',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'edit',
    disableSortBy: true,
  },
];

const sortByString = (strA: string, strB: string, desc = false) => {
  return desc ? strB.localeCompare(strA) : strA.localeCompare(strB);
};

const sortByDate = (dateA: Date, dateB: Date, desc = false) => {
  return desc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
};

const sortByStatus = (statusA: number, statusB: number, desc = false) => {
  return desc ? statusB - statusA : statusA - statusB;
};

export const MigrationsTable: React.FC<MigrationsTableProps> = ({
  migrations,
  showMigrationForm,
  migrationEditButtonAllowed,
  migrationViewButtonAllowed,
}) => {
  const { t } = useTranslation(['common', 'migrations']);
  const navigate = useNavigate();

  const [tablePageNumber, setTablePageNumber] = React.useState(0);
  const [tablePageSize, setTablePageSize] = React.useState(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = React.useState('prefix');
  const [sortDesc, setSortSesc] = React.useState(false);

  const handlePaginationCallback = React.useCallback((pageIndex: number, pageSize: number) => {
    setTablePageNumber(pageIndex);
    setTablePageSize(pageSize);
  }, []);

  const handleSortByCallback = React.useCallback((sortParams: any) => {
    setSortBy(sortParams[0]?.id);
    setSortSesc(!!sortParams[0]?.desc);
  }, []);

  const sortedData = React.useMemo(() => {
    const sortRows = (migrationA: MigrationSummaryDTO, migrationB: MigrationSummaryDTO) => {
      switch (sortBy) {
        case 'name':
          return sortByString(migrationA.name, migrationB.name, sortDesc);
        case 'status':
          return sortByStatus(migrationA.status, migrationB.status, sortDesc);
        case 'createdDate':
          return sortByDate(new Date(migrationA.createdAt!), new Date(migrationB.createdAt!), sortDesc);
        case 'completionDate':
          return sortByDate(new Date(migrationA.completedAt!), new Date(migrationB.completedAt!), sortDesc);
        default:
          return sortByString(migrationA.name, migrationB.name);
      }
    };

    const dataSort = [...migrations];
    dataSort.sort(sortRows);
    return dataSort;
  }, [migrations, sortBy, sortDesc]);

  const rows = React.useMemo(
    () =>
      sortedData.slice(tablePageNumber * tablePageSize, tablePageNumber * tablePageSize + tablePageSize).map(
        (migration, i): QDataRow => ({
          index: i,
          name: migration.name,
          status: migration.status,
          createdDate: migration.createdAt || '',
          completionDate: migration.completedAt || '',
          view: migrationViewButtonAllowed(migration.status) && (
            <QButton onClick={() => navigate(`/${migration.id}`)} variant="link">
              {t('lblView')}
            </QButton>
          ),
          edit: migrationEditButtonAllowed(migration.status) && (
            <QButton
              onClick={() => showMigrationForm(migration.id, migration.name, migration.changeControlStatement)}
              variant="link"
            >
              {t('lblEdit')}
            </QButton>
          ),
          muid: migration.id,
        }),
      ),
    [
      sortedData,
      tablePageNumber,
      tablePageSize,
      migrationViewButtonAllowed,
      migrationEditButtonAllowed,
      navigate,
      showMigrationForm,
      t,
    ],
  );

  const calculatePageAmount = React.useMemo(() => {
    if (migrations.length === 0) {
      return 1;
    }

    return Math.ceil(migrations.length / tablePageSize);
  }, [migrations.length, tablePageSize]);

  return (
    <>
      <QDataTable
        hasPagination={migrations.length > 0}
        onRowClick={() => 'Clicked'}
        columns={getColumns(t)}
        data={rows}
        hasPageSizeOptions={migrations.length > 0}
        manualPagination={{
          initialPageSize: tablePageSize,
          paginationCallback: handlePaginationCallback,
          pageCount: calculatePageAmount,
        }}
        manualSortBy={{
          sortByCallback: handleSortByCallback,
        }}
      />
      {migrations.length === 0 && <EmptyStateRow />}
    </>
  );
};

const EmptyStateRow: React.FC = () => (
  <QTable mt="0px !important" aria-label="MigrationListTable">
    <QTbody>
      <QTr>
        <QTd textAlign="center" colSpan={6}>
          {t('migrations:lblNoMigrations')}
        </QTd>
      </QTr>
    </QTbody>
  </QTable>
);
