import { HTTP422Error } from '../errors';
import { TextEncoder } from '../util';

// https://en.wikipedia.org/wiki/Filename#Comparison_of_filename_limitations
export type SerializedFileName = string;

export class FileName {
  readonly value: string;
  constructor(input: string) {
    this.value = input;
    this.isMacCompatible();
    this.containsNoIllegalCharacters();
    this.isWindowsCompatible();
    this.hasNoStartingHyphen();
  }
  public meetsLengthCriteria(): true {
    const length = new TextEncoder().encode(this.value).length;
    if (length === 0) {
      throw new Error('filename is empty');
    }
    if (length > 255) {
      throw new Error('filename is more than 255 characters');
    }
    return true;
  }
  public hasNoForwardSlash(): true {
    if (/\//.test(this.value)) {
      throw new Error('filename contains / forward slash character');
    }
    return true;
  }
  public hasNoNULChar(): true {
    // eslint-disable-next-line no-control-regex
    if (/\x00/.test(this.value)) {
      throw new Error('filename contains NUL ASCII character');
    }
    return true;
  }
  public hasNoColonChar(): true {
    if (/:/.test(this.value)) {
      throw new Error('filename contains colon : character ');
    }
    return true;
  }
  public hasNoControlChars(): true {
    // eslint-disable-next-line no-control-regex
    if (/[\x01-\x1F]/.test(this.value)) {
      throw new Error('filename contains control ASCII characters 0-31');
    }
    return true;
  }
  public isUNIXCompatible(): true {
    this.meetsLengthCriteria();
    this.hasNoForwardSlash();
    this.hasNoNULChar();
    return true;
  }
  public isMacCompatible(): true {
    this.hasNoColonChar();
    this.isUNIXCompatible();
    return true;
  }
  public isWindowsCompatible(): true {
    this.hasNoControlChars();
    this.hasNoNULChar();
    this.hasNoForwardSlash();
    // https://gist.github.com/doctaphred/d01d05291546186941e1b7ddc02034d3
    // eslint-disable-next-line no-control-regex
    if (/[<>:"/\\|?*\x00-\x1F]/.test(this.value)) {
      throw new Error('contains reserved characters: < > : " / \\ | ? *');
    }
    if (/^ /.test(this.value)) {
      throw new Error('first character cannot be a space');
    }
    if (/[ .]$/.test(this.value)) {
      throw new Error('last character cannot be a space or a period');
    }
    if (
      /^(CON|PRN|AUX|NUL|COM1|COM2|COM3|COM4|COM5|COM6|COM7|COM8|COM9|LPT1|LPT2|LPT3|LPT4|LPT5|LPT6|LPT7|LPT8|LPT9)(\..+)?$/.test(
        this.value.toUpperCase(),
      )
    ) {
      throw new Error('filename is reserved');
    }
    return true;
  }

  public hasNoStartingHyphen(): true {
    if (/^-/.test(this.value)) {
      throw new Error('cannot start with hyphen');
    }
    return true;
  }

  public containsNoIllegalCharacters(): true {
    // \p{L} matches any letter from any language
    // \p{M} matches combo letters with accents, umlauts, etc.
    const match = this.value.match(/[^\p{L}\p{M}0-9,._ \-()+'`&!@#$%^]/u);
    if (match) {
      throw new Error(`cannot contain ${match}`);
    }
    return true;
  }

  public serialize(): SerializedFileName {
    return this.value;
  }
  static deserialize(input: unknown) {
    if (typeof input === 'string') {
      return new FileName(input);
    }
    throw new HTTP422Error(['Specified FileName not a string']);
  }
  toString() {
    return this.value.toString();
  }
}
