import React, { useState } from 'react';
import { QBox, QButton, QCheckbox, QDivider, QText } from '@qualio/ui-components';
import { useTranslation } from 'react-i18next';
import { ApiUser } from '../../../models';

export const SelectApprovers: React.FC<{
  onClose: VoidFunction;
  approvers: ApiUser[];
  onSubmit: (approvers: ApiUser[]) => void;
  preSelectedApprovers: ApiUser[];
}> = ({ onClose, approvers, onSubmit, preSelectedApprovers }) => {
  const { t } = useTranslation(['common', 'migrations']);
  const [selectedApprovers, setSelectedApprovers] = useState<ApiUser[]>(preSelectedApprovers);

  const preSelectedUserEmails = selectedApprovers.map((p) => p.email);

  const handleSelectionChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    const { checked, value } = event.currentTarget;
    if (checked) {
      const approver = approvers.find((approver) => approver.email === value);
      const errUnableToFindApprover = t('files:errUnableToFindApprover', {
        email: value,
      });
      if (!approver) throw new Error(errUnableToFindApprover);
      setSelectedApprovers((prev) => [...prev, approver]);
    } else {
      setSelectedApprovers((prev) => [...prev.filter((app) => app.email !== value)]);
    }
  };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    if (selectedApprovers) onSubmit(selectedApprovers);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className="flex--column pt-1">
      <QText>{t('files:lblSelectApproversModalDetails')}</QText>
      <QText fontSize="lg">{t('files:lblQualityApprovers')}</QText>
      <QText fontSize="sm">{t('files:lblQualityApproversHint')}</QText>
      {approvers
        .filter((approver) => approver.isQM)
        .map((approver) => (
          <QCheckbox
            key={approver.email}
            value={approver.email}
            onChange={handleSelectionChange}
            isDisabled={approver.invite_status !== 'accepted'}
            isChecked={preSelectedUserEmails.some((userEmail) => userEmail === approver.email)}
          >
            {approver.fullName || approver.email}
          </QCheckbox>
        ))}
      <QText fontSize="lg">{t('files:lblPotentialApprovers')}</QText>
      {approvers
        .filter((approver) => !approver.isQM)
        .map((approver) => (
          <QCheckbox
            key={approver.email}
            value={approver.email}
            onChange={handleSelectionChange}
            isDisabled={approver.invite_status !== 'accepted'}
            isChecked={preSelectedUserEmails.some((userEmail) => userEmail === approver.email)}
          >
            {approver.fullName || approver.email}
          </QCheckbox>
        ))}
      <QDivider my={3} />
      <QBox display="flex" justifyContent="space-between">
        <QButton variant="outline" onClick={onClose}>
          {t('lblCancel')}
        </QButton>
        <QButton type="submit">{t('lblSave')}</QButton>
      </QBox>
    </form>
  );
};
