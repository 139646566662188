import {
  QButton,
  QInput,
  QTextarea,
  QBox,
  QFormControl,
  useToastProvider,
  useCurrentUser,
} from '@qualio/ui-components';
import React, { useState } from 'react';
import { DrawerState } from './ListMigrations';
import { MigrationsApi } from '../../../services';
import { useQueryClient } from 'react-query';
import { trim, size } from 'lodash';
import { DEFAULT_CC, STALE_USER_COMPANY_VALIDATION_ERROR_MESSAGE } from '../../../constants';

export const MigrationDetailsForm: React.FC<{
  onClose: VoidFunction;
  migration: DrawerState;
  successMessage: string;
}> = ({ onClose, migration, successMessage }) => {
  const [name, setName] = useState(migration.migrationName || '');
  const [statement, setStatement] = useState(migration.changeControlStatement || DEFAULT_CC);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();
  const queryClient = useQueryClient();

  const handleSuccess = async () => {
    showToast({
      title: 'Success',
      description: successMessage,
      status: 'success',
    });
    queryClient.invalidateQueries();
  };

  const handleError = (error: any) => {
    if (error.response.status === 403) {
      showToast({
        title: 'Outdated User Information',
        description: STALE_USER_COMPANY_VALIDATION_ERROR_MESSAGE,
        duration: 10000,
        status: 'warning',
        id: `warning-toast-1`,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast({
        title: 'Error',
        description: error?.response?.data?.message?.message || 'Unable to create a migration due to an error',
        status: 'error',
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (migration.id) {
      await MigrationsApi.updateMigration(
        {
          id: migration.id,
          migrationName: name,
          changeControlStatement: statement,
        },
        handleSuccess,
        handleError,
      );
    } else {
      await MigrationsApi.createMigration(companyId, name, statement, handleSuccess, handleError);
    }
    onClose();
    setIsLoading(false);
  };

  // Set labels that cannot render inline
  const lblMigrationName = 'Migration Name';
  const lblChangeControlStatement = 'Change Control Statement';
  const invalidName = size(trim(name)) < 5;
  const invalidStatement = size(trim(statement)) < 20;
  const invalidForm = invalidName || invalidStatement;

  return (
    <form>
      <QFormControl mb={5} isInvalid={invalidName} label={lblMigrationName} error="Please enter at least 5 characters">
        <QInput placeholder={lblMigrationName} value={name} onChange={(e) => setName(e.target.value)} />
      </QFormControl>
      <QFormControl
        mb={5}
        isInvalid={invalidStatement}
        label={lblChangeControlStatement}
        error="Please enter at least 20 characters"
      >
        <QTextarea
          value={statement}
          onChange={(e) => setStatement(e.target.value)}
          name="changeControlStatement"
          rows={6}
        />
      </QFormControl>
      <QBox display="flex" justifyContent="space-between">
        <QButton variant="outline" onClick={onClose}>
          Cancel
        </QButton>
        <QButton onClick={handleSubmit} isDisabled={invalidForm} isLoading={isLoading}>
          Save
        </QButton>
      </QBox>
    </form>
  );
};
