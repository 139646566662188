import { MigrationError } from '../../models';
import {
  DeleteFileSuccess,
  FetchFiles,
  FetchFilesError,
  FetchFilesSuccess,
  FileActionType,
  FileValidationErrors,
  MigrationFile,
} from '../types';

export const fetchFiles = (): FetchFiles => ({
  type: FileActionType.FetchFiles,
});

export const fetchFilesSuccess = (payload: MigrationFile[]): FetchFilesSuccess => ({
  type: FileActionType.FetchFilesSuccess,
  payload,
});

export const fetchFilesError = (error: Error): FetchFilesError => {
  return {
    type: FileActionType.FetchFilesError,
  };
};

export const deleteFileSuccess = (fileId: string): DeleteFileSuccess => {
  return {
    type: FileActionType.DeleteFileSuccess,
    payload: fileId,
  };
};

export const fileValidationErrors = (payload?: MigrationError[]): FileValidationErrors => {
  return {
    type: FileActionType.FileValidationErrors,
    payload,
  };
};
